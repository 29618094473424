import $ from 'jquery';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { awsUrl } from '../config';
import { isNull } from 'util';
window.jQuery = $;
window.$ = $;
global.jQuery = $;
require('bootstrap');
export function openNav() {
	document.getElementById("mySidenav").style.width = "280px";
}
export function closeNav() {
	document.getElementById("mySidenav").style.width = "0";
}
export function clearErrHistoricalClaims(e, historicalClaimsErrorStore, key) {
	let year = e.target.id.split("-")[1];
	if (historicalClaimsErrorStore[year]) {
		historicalClaimsErrorStore[year][key] = false;
		this.setState({ historicalClaimsErrorStore });
	}
}

export function clearErr(e) {
	var element = e.target
	$(element).css('border-color', '#ababab');
	$(element).siblings('.errMsg').html('');
	$('#loginSubmit').siblings('.errMsg').html('');
	$('#emailStatus').css('border-color', '#ababab');
	$('#emailStatus').siblings('.errMsg').html('');
	$('#profileSaveButton').siblings('.errMsg').html('');
	$('#changeSubmit').siblings('.errMsg').html('');
	if (element.name === "classCode") {
		let stateBlock = Number(element.id.charAt(element.id.length - 3));
		let classNumber = element.id.charAt(element.id.length - 2);
		$('#invalidCode' + stateBlock + classNumber).html('');
	}
}
export function onlyNum(e) {
	var reg = /^[0-9\b]+$/;
	var element = e.target;
	var isValid = reg.test(element.value);
	let errorMsg;
	if (element.value.trim()) {
		errorMsg = " invalid";
	} else {
		errorMsg = " required";
	}
	if ((!isValid) && element.value !== '') {
		$(element).css('border-color', 'red');
		$(element).siblings('.errMsg').html(errorMsg);
	}
}

export function onlyAlpha(e) {
	var reg = /^[a-z A-Z\b]+$/;
	var element = e.target;
	var isValid = reg.test(element.value);
	let errorMsg;
	if (element.value.trim()) {
		errorMsg = " invalid";
	} else {
		errorMsg = " required";
	}
	if ((!isValid) && element.value !== '') {
		$(element).css('border-color', 'red');
		$(element).siblings('.errMsg').html(errorMsg);
	}
}
export function alphaNum(e) {
	var reg = /^[a-z0-9A-Z\b]+$/;
	var element = e.target;
	var isValid = reg.test(element.value);
	let errorMsg;
	if (element.value.trim()) {
		errorMsg = " invalid";
	} else {
		errorMsg = " required";
	}
	if ((!isValid) && element.value !== '') {
		$(element).css('border-color', 'red');
		$(element).siblings('.errMsg').html(errorMsg);
	}
}
export function validEmail(e) {
	var element = e.target;
	var reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	var isValid = reg.test(element.value);
	if ((!isValid) && element.value !== '') {
		$(element).css('border-color', 'red');
		$(element).siblings('.errMsg').html(' format "john.doe@example.com"');
	}
}
export function validPassword(e) {
	var element = e.target;
	var reg = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
	var isValid = reg.test(element.value);
	if ((!isValid) && element.value !== '') {
		$(element).css('border-color', 'red');
		$(element).siblings('.errMsg').html('Password at least 8 digits with upper, lower case alphabet, Numbers and special characters.')
	}
}
export function setCookie(prospectId, exdays) {
	var d = new Date();
	d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
	var expires = "expires=" + d.toUTCString();
	document.cookie = "prospectId =" + prospectId + ";" + expires + ";path=/";
}

function toDataUrl(url, callback) {
	var xhr = new XMLHttpRequest();
	xhr.onload = function () {
		var reader = new FileReader();
		reader.onloadend = function () {
			callback(reader.result);
		}
		reader.readAsDataURL(xhr.response);
	};
	xhr.open('GET', url);
	xhr.responseType = 'blob';
	xhr.send();
}

export function downloadPDF(event) {
	window.open('./BinderDownload', '_blank');
}

export function changeText() {
	$(".changeLater").children().text('Login');
}

export function printDiv() {
	let mywindow = window.open('./BinderDownload', '_blank');
	setTimeout(
		function () {
			mywindow.document.title = 'Bind'
			mywindow.print();
			mywindow.close();
		}, 4000);
}

export async function startFresh() {
	$("a[href='#binder'],a[href='#cmprRate'],a[href='#undrQues'],a[href='#cmprQts'],a[href='#payBind']").addClass('blocked');
	$('#progressStatus li:nth-child(9)>.progressUnit,#progressStatus li:nth-child(7)>.progressUnit,#progressStatus li:nth-child(5)>.progressUnit,#progressStatus li:nth-child(3)>.progressUnit,#progressStatus li:nth-child(1)>.progressUnit').removeClass('unitDone');
	$('#progressStatus li:nth-child(10)>.progressLine,#progressStatus li:nth-child(8)>.progressLine,#progressStatus li:nth-child(6)>.progressLine,#progressStatus li:nth-child(4)>.progressLine,#progressStatus li:nth-child(2)>.progressLine').removeClass('lineDone');
	// let email = JSON.stringify(sessionStorage.getItem('currProspect')).emailAddress;
	$('#loader').css('display', 'block')
	Auth.currentSession().then(async res => {
		const header = await getHeader();
		let userName = res.accessToken.payload.username;
		console.log("username:", userName, header);
		axios.delete(awsUrl + "/api/deleteProsepectData/" + userName, header).then(res => {
			deleteAllCookiesSelf();
			sessionStorage.clear();
			sessionStorage.setItem('formStage', 'one');
			sessionStorage.setItem('isLoggedIn', true)
			window.location.reload();
		}).catch(err => {
			console.log("err", err);
			showErrAlert("Got a problem in Start Fresh activity");
		})
	}).catch(err => {
		if (err === "No current user") {
			console.log("Current Authenticated User: ", err);
		}
	});
}

function deleteAllCookiesSelf() {
	var cookies = document.cookie.split(";");

	for (var i = 0; i < cookies.length; i++) {
		var cookie = cookies[i];
		var eqPos = cookie.indexOf("=");
		var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
		document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
	}
}

export function deleteAllCookies() {
	var cookies = document.cookie.split(";");

	for (var i = 0; i < cookies.length; i++) {
		var cookie = cookies[i];
		var eqPos = cookie.indexOf("=");
		var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
		document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
	}
}
export async function getUserAuthStatus(username, password) {
	var userTemp = await Auth.signIn(username, password).then(function (res) {
		console.log("response", res);
		return res;
	}).catch(function (err) {
		console.log("error:", err);
		return err.code;
	});
	console.log("Comming here", userTemp);
	return userTemp;
}

export async function logout() {
	Auth.signOut().then(function (res) {
		console.log("Succesfully logout:", res);
		deleteAllCookiesSelf();
		window.location.href = './Login';
		sessionStorage.clear();
		localStorage.clear();
	}).catch((err) => {
		console.log("error in logout: ", err);
	})
}

export function showErrAlert(err) {
	if (err === "Request failed with status code 503") {
		alert("Oops! Server not responding");
	} else {
		alert(err);
	}
}

export function blobPdfFromBase64String(base64String) {
	const byteArray = Uint8Array.from(
		base64String
		// .map(char => char.charCodeAt(0))
	);
	return new Blob([byteArray], { type: 'application/pdf' });
};
export async function getHeader() {
	let token = await Auth.currentSession().then(res => {
		const USER_TOKEN = res.idToken.jwtToken;
		const header = { headers: { Authorization: 'Bearer ' + USER_TOKEN } };
		return header;
	}).catch(err => {
		if (err === "No current user") {
			const header = { headers: { Authorization: 'Bearer ' + '' } };
			return header;
		}
	});
	return token;
}

export function clearDelete(data, block, addressBlockNo) {
	if (block === "classBlock" && Object.values(data).length <= 1) {

		$('#delete-class-code' + addressBlockNo).css('display', 'none');
	} else if (block === "addressBlock" && Object.values(data).length <= 1) {
		$('.address-delete').css("display", "none");
	} else {
		$('#delete-class-code' + addressBlockNo).css('display', 'block');
		$('.address-delete').css("display", "block");
	}
}
