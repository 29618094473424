import React, { Component } from "react";
import DescriptionCode from "../../subcompo/DescriptionCode";
import NumberFormat from "react-number-format";
import { Form } from "react-bootstrap";

const axios = require("axios");
class Newcode extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate() {
    if (this.props.locInfoParent !== this.state.locInfo) {
      this.setState({ locInfo: this.props.locInfoParent });
    }
    if (this.props.locInfoErrorStoreParent !== this.state.locInfoErrorStore) {
      this.setState({ locInfoErrorStore: this.props.locInfoErrorStoreParent });
    }
  }

  componentDidMount() {
    const { locInfoParent } = this.props;
    this.setState({ locInfo: locInfoParent });
  }

  setClassCodeDescription = (
    classCodeDescription,
    addressBlockNo,
    addressChildNo
  ) => {
    const { updateLocInfo } = this.props;
    updateLocInfo(
      addressBlockNo,
      addressChildNo,
      "classCodeDescription",
      classCodeDescription
    );
    // this.setState({ classCodeDescription });
  };

  render() {
    const {
      addressChildNo,
      addressBlockNo,
      rmvChild,
      state,
      updateLocInfo,
      handleMinMax,
      updateLocInfoErrorStore,
    } = this.props;

    const { locInfo, locInfoErrorStore } = this.state;
    let codeCount = 1;
    let min, max;
    try {
      codeCount = Object.keys(locInfo[addressBlockNo]).length;
    } catch (e) {}

    let classCodeDescription;
    let locInfoBlock = {};
    let minMax = { min: undefined, max: undefined };
    if (locInfo && locInfo[addressBlockNo][addressChildNo]) {
      classCodeDescription =
        locInfo[addressBlockNo][addressChildNo].classCodeDescription;
      locInfoBlock = {
        ft: locInfo[addressBlockNo][addressChildNo].ft,
        pt: locInfo[addressBlockNo][addressChildNo].pt,
        payroll: locInfo[addressBlockNo][addressChildNo].payroll,
      };
      if (locInfo[addressBlockNo][addressChildNo].manPremRange) {
        minMax = locInfo[addressBlockNo][addressChildNo].manPremRange;
      }
    }

    if (!minMax.min) {
      min = "-";
    } else if (minMax.min === "N/A") {
      min = "N/A";
    } else {
      min = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      }).format(minMax.min);
    }

    if (!minMax.max) {
      max = "-";
    } else if (minMax.max === "N/A") {
      max = "N/A";
    } else {
      max = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      }).format(minMax.max);
    }

    return (
      <div className="row calcPremium grey">
        <DescriptionCode
          updateLocInfo={updateLocInfo}
          addressBlockNo={addressBlockNo}
          addressChildNo={addressChildNo}
          state={state}
          handleMinMax={handleMinMax}
          classCodeDescription={classCodeDescription}
          locInfoParent={locInfo}
          locInfoErrorStoreParent={locInfoErrorStore}
          updateLocInfoErrorStore={updateLocInfoErrorStore}
        />
        <span
          id={"invalidCode" + addressBlockNo + addressChildNo}
          style={{ color: "red" }}
        ></span>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-3 text-left grey">
              <Form.Group>
                <label
                  for={"classCodes" + addressBlockNo + addressChildNo + "1"}
                  title="Number of full time employees"
                  className="mandatory"
                >
                  FT
                </label>
                <NumberFormat
                  title="Number of full time employees"
                  id={"classCodes" + addressBlockNo + addressChildNo + "1"}
                  isNumericString={true}
                  onKeyUp={(event) => {
                    updateLocInfo(
                      addressBlockNo,
                      addressChildNo,
                      "ft",
                      event.target.value
                    );
                    updateLocInfoErrorStore(
                      addressBlockNo,
                      addressChildNo,
                      "ft",
                      false
                    );
                  }}
                  style={{
                    borderColor:
                      locInfoErrorStore &&
                      locInfoErrorStore[addressBlockNo] &&
                      locInfoErrorStore[addressBlockNo][addressChildNo] &&
                      locInfoErrorStore[addressBlockNo][addressChildNo].ft
                        ? "red"
                        : "",
                  }}
                  value={locInfoBlock.ft}
                  disabled={false}
                  onBlur={() => handleMinMax(addressBlockNo, addressChildNo)}
                  name="FT"
                  className="text-center"
                  format="###"
                  placeholder="# FT"
                  type="text"
                  autoComplete="off"
                />
              </Form.Group>
            </div>
            <div className="col-sm-3 text-left grey">
              <Form.Group>
                <label
                  for={"classCodes" + addressBlockNo + addressChildNo + "2"}
                  title="Number of part time employees"
                  className="mandatory"
                >
                  PT
                </label>
                <NumberFormat
                  id={"classCodes" + addressBlockNo + addressChildNo + "2"}
                  title="Number of part time employees"
                  onKeyUp={(event) => {
                    updateLocInfo(
                      addressBlockNo,
                      addressChildNo,
                      "pt",
                      event.target.value
                    );
                    updateLocInfoErrorStore(
                      addressBlockNo,
                      addressChildNo,
                      "pt",
                      false
                    );
                  }}
                  style={{
                    borderColor:
                      locInfoErrorStore &&
                      locInfoErrorStore[addressBlockNo] &&
                      locInfoErrorStore[addressBlockNo][addressChildNo] &&
                      locInfoErrorStore[addressBlockNo][addressChildNo].pt
                        ? "red"
                        : "",
                  }}
                  value={locInfoBlock.pt}
                  disabled={false}
                  onBlur={() => handleMinMax(addressBlockNo, addressChildNo)}
                  name="PT"
                  className="text-center"
                  format="###"
                  placeholder="# PT"
                  type="text"
                  autoComplete="off"
                />
              </Form.Group>
            </div>
            <div className="col-sm-6 text-left grey">
              <Form.Group>
                <label
                  for={"classCodes" + addressBlockNo + addressChildNo + "3"}
                  className="mandatory"
                  title="Annual Payroll"
                >
                  Annual Payroll
                </label>
                <NumberFormat
                  title="Annual Payroll"
                  id={"classCodes" + addressBlockNo + addressChildNo + "3"}
                  onKeyUp={(event) => {
                    updateLocInfo(
                      addressBlockNo,
                      addressChildNo,
                      "payroll",
                      event.target.value
                    );
                    updateLocInfoErrorStore(
                      addressBlockNo,
                      addressChildNo,
                      "payroll",
                      false
                    );
                  }}
                  style={{
                    borderColor:
                      locInfoErrorStore &&
                      locInfoErrorStore[addressBlockNo] &&
                      locInfoErrorStore[addressBlockNo][addressChildNo] &&
                      locInfoErrorStore[addressBlockNo][addressChildNo].payroll
                        ? "red"
                        : "",
                  }}
                  value={locInfoBlock.payroll}
                  disabled={false}
                  name="payroll"
                  onBlur={() => handleMinMax(addressBlockNo, addressChildNo)}
                  placeholder="Annual Payroll ($)"
                  thousandSeparator={true}
                  prefix={"$"}
                  className="text-center"
                  type="text"
                  autoComplete="off"
                />
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="col-sm-1 text-center code-delete">
          <br />
          {codeCount > 1 && (
            <button
              id={"delete-class-code" + addressBlockNo + addressChildNo + "4"}
              type="button"
              className="rmvLoc btnInvisible transpButton"
              data-address={addressBlockNo}
              data-classno={addressChildNo}
            >
              <img
                onClick={(event) => {
                  rmvChild(addressChildNo);
                }}
                src={require("../../../images/delete4.png")}
                data-address={addressBlockNo}
                data-classno={addressChildNo}
                alt=""
              />
            </button>
          )}
        </div>
        {
          <div className="col-sm-4 relDiv rangeSlider">
            <div className="row justify-content-center">
              <div id="minVal" className="premVal">
                <span className="minValueCC">{min}</span>
              </div>
              <div className="barBG"></div>
              <div id="maxVal" className="premVal">
                <span className="manValueCC">{max}</span>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}
export default Newcode;
