import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
class Datepick extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selDate: moment(this.props.date),
    };
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidUpdate() {
    if (this.state.selDate.format("L") !== this.props.date) {
      this.setState({ selDate: moment(this.props.date) });
    }
  }

  handleDateChange(date) {
    const { updateEmodRates, dateNo, questionNumber } = this.props;
    let formattedDate;
    try {
      formattedDate = date.format("L");
      updateEmodRates(questionNumber, formattedDate, dateNo);
    } catch (e) {}
  }

  render() {
    return (
      <DatePicker
        style={{ borderColor: "red" }}
        selected={this.state.selDate}
        onChange={this.handleDateChange}
      />
    );
  }
}
export default Datepick;
