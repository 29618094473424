import styles from "./Video.module.css"

import * as React from "react";


export default function Video() {
  
  return (
    <div className={"container "+styles.videoContainer}>
   <div id="carouselExampleIndicators" className="carousel slide" >
  
  <div className="carousel-inner">
  

    <div className="carousel-item active">
    <div
            className={
              "embed-responsive embed-responsive-16by9 " + styles.iframeWrapper
            }
          >
    <iframe
              className="embed-responsive-item"
              src="https://www.youtube.com/embed/QjcfacCaWoc?rel=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            </div>

    </div>
    <div className="carousel-item">
    <div
            className={
              "embed-responsive embed-responsive-16by9 " + styles.iframeWrapper
            }
          >
   <iframe src="https://www.youtube.com/embed/BF9_EdUYa3c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>

    
    </div>
    
   
  </div>
  <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true" />
    <span className="sr-only">Previous</span>
  </a>
  <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true" />
    <span className="sr-only">Next</span>
  </a>
  <ol className="carousel-indicators" style={{position:"static"}}>
    <li data-target="#carouselExampleIndicators" data-slide-to={0} className="active" style={{width:"100px",backgroundColor:"#2979D4",textIndent:"0",textAlign:"center"}}>1</li>
    <li data-target="#carouselExampleIndicators" data-slide-to={1} style={{width:"100px",backgroundColor:"#2979D4",textIndent:"0",textAlign:"center"}}>2</li>
  </ol>
</div>
</div>

  );
}