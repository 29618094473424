// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:f5d347de-68d8-40c1-b65e-97d0df6df422",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_yqxpRdDpS",
    "aws_user_pools_web_client_id": "4g3f16ghe5udk05udri1ihcl5t"
};


export default awsmobile;
