// here we shall design the new progress bar and see how to resuse it
import React from "react";
const width = {
  one: "16.66%",
  two: "33.33%",
  three: "50%",
  four: "66.66%",
  five: "83.33%",
  six: "100%",
};

function NewProgress(props) {
  const stage = sessionStorage.getItem("formStage");
  return (
    <div className="color-bar">
      <span style={{ width: `${width[stage]}` }}></span>
    </div>
  );
}

export default NewProgress;
