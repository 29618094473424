import React, { useContext } from "react";
import { ContactFormContext } from "./ContactFormContext";
import styles from "./Getstarted.module.css";

export default function Getstarted() {
  const { setShowForm } = useContext(ContactFormContext);
  return (
    <div className={styles.getstarted}>
      <h1 className="text-white mx-2 mb-5">Get started today</h1>
      {/* <p className="text-white w-50 text-center my-4">
          something about haloween Never forget what you are. The rest of the
          world will not. Wear it like armor, and it can never be used to hurt
          you.</p> */}
      <button
        className={styles.getquotewhitebutton + " text-primary"}
        onClick={() => {
          setShowForm(true);
        }}
      >
        Get a quote
      </button>
    </div>
  );
}
