import React from "react";
import pageStyles from "../pages/NewHome.module.css";
import { Form, Button } from "react-bootstrap";
import {useForm} from "react-hook-form";
import axios from "axios";

export default function ContactForm(props) {
  const { register, handleSubmit, setError, formState: { errors } } = useForm();
  const onFormSubmitted = (data) => {
      axios.post("https://lh8add1jfj.execute-api.us-east-1.amazonaws.com/prod/mail-form/insurecomp", {data: data})
        .then(() => {
          props.onFormSubmitted();
        })
        .catch(() => {
          setError("name", {
            type: "manual",
            message: "Could not submit your details. Please try again."
          })
        });
  }
  console.log(errors)
  return (
    <div className={pageStyles.landingWrapper}>
      <div class="row">
        <div class="col-12">
            <Form onSubmit={handleSubmit(onFormSubmitted)}>
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Name" isInvalid={errors.name} {...register("name", {required: {value: true, message: "Name is required"} })}/>
                {errors.name? (<Form.Control.Feedback type="invalid">{errors.name.message}</Form.Control.Feedback>): null}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formCompany">
                <Form.Label>Company</Form.Label>
                <Form.Control type="text" placeholder="Company name" isInvalid={errors.company} {...register("company", {required:true})}/>
                {errors.company? (<Form.Control.Feedback type="invalid">Company is required</Form.Control.Feedback>): null}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Enter email" isInvalid={errors.email} {...register("email", {required:true})}/>
                {errors.email? (<Form.Control.Feedback type="invalid">Email is required</Form.Control.Feedback>): null}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formPhone">
                <Form.Label>Phone</Form.Label>
                <Form.Control type="text" placeholder="Enter phone" isInvalid={errors.phone} {...register("phone", {required:true})}/>
                {errors.phone? (<Form.Control.Feedback type="invalid">Phone is required</Form.Control.Feedback>): null}
              </Form.Group>

              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
        </div>
      </div>
    </div>
  );
}
