import React, { Component } from "react";
import { proceedBind2, quoteView } from "../../../utils/form_comp_quote";
import axios from "axios";
import { Auth } from "aws-amplify";
import moment from "moment";
import { awsUrl2 } from "../../../config";
let numeral = require("numeral");

class BindQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ocrDisableBind: true,
      showOcrStatus: true,
      parseStatus: "'Loss File' OCR check is in progress...",
    };
    this.quoteView = quoteView.bind(this);
  }

  componentDidMount() {
    try {
      let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
      let lId = currProspect.lId;
      this.s3Poller(lId);
    } catch (error) {
      this.setState({
        ocrDisableBind: false,
        showOcrStatus: false,
        parseStatus: "Issues in loss file parsing!",
        parseStatusCss: "loss-parse-ocr-failure",
      });
    }
  }

  s3Poller = (lId) => {
    Auth.currentSession().then((res) => {
      const USER_TOKEN = res.idToken.jwtToken;
      const header = { headers: { Authorization: "Bearer " + USER_TOKEN } };
      axios
        .get(awsUrl2 + `/api/s3Poller/loss-run-ocr/${lId}/us-east-1`, header)
        .then((result) => {
          if (result.data.fileType) {
            this.handleLossOcrParse(result.data);
          } else {
            this.s3Poller(lId);
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    });
  };

  createHistoricalClaimsObject = (historicalClaims) => {
    let historicalClaimsObject = {};
    for (let obj of historicalClaims) {
      historicalClaimsObject[obj.year] = obj;
    }

    return historicalClaimsObject;
  };

  checkLossVariance = (data) => {
    let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
    let { effectiveDate, historicalClaims } = currProspect;
    let year = moment(effectiveDate).year();
    let historicalClaimsObject =
      this.createHistoricalClaimsObject(historicalClaims);
    let flag = true;

    try {
      for (let i = 0; i < 5; i++) {
        let key = year - i;
        if (data[key] && historicalClaimsObject[key]) {
          let userClaimCount = numeral(
            historicalClaimsObject[key].claim
          ).value();
          let userIncurred = numeral(
            historicalClaimsObject[key].totInc
          ).value();
          let lossClaimCount = numeral(data[key].count).value();
          let lossIncurred = numeral(data[key].incurred).value();
          let claimCountVariance = numeral(lossClaimCount)
            .multiply(30)
            .divide(100)
            .value();
          let incurredVariance = numeral(lossIncurred)
            .multiply(10)
            .divide(100)
            .value();
          console.log("++", lossClaimCount, userClaimCount, claimCountVariance);
          console.log("++", lossIncurred, userIncurred, incurredVariance);
          if (
            userClaimCount >=
              numeral(lossClaimCount).subtract(claimCountVariance).value() &&
            userClaimCount <=
              numeral(lossClaimCount).add(claimCountVariance).value() &&
            userIncurred >=
              numeral(lossIncurred).subtract(incurredVariance).value() &&
            userIncurred <= numeral(lossIncurred).add(incurredVariance).value()
          ) {
            flag = true;
          } else {
            flag = false;
            break;
          }
        } else if (!data[key] && !historicalClaimsObject[key]) {
          flag = true;
        } else {
          flag = false;
          break;
        }
      }
    } catch (error) {
      flag = true;
    }

    return flag;
  };

  handleLossOcrParse = (data) => {
    if (!data.fileType) {
      this.setState({ ocrDisableBind: false, showOcrStatus: false });
    } else {
      if (data.fileType === "success") {
        let lossDataOk = this.checkLossVariance(data.data);
        if (lossDataOk) {
          this.setState({
            ocrDisableBind: false,
            showOcrStatus: false,
            parseStatus: "Loss file matches!",
            parseStatusCss: "loss-parse-success",
          });
        } else {
          this.setState({
            ocrDisableBind: true,
            parseStatus:
              "‘Loss run’ uploaded does not match with claims information provided. Please check and update.",
            parseStatusCss: "loss-parse-error",
          });
        }
      } else {
        this.setState({
          ocrDisableBind: false,
          showOcrStatus: false,
          parseStatus: "Issues in loss file parsing!",
          parseStatusCss: "loss-parse-ocr-failure",
        });
      }
    }
  };

  render() {
    const {
      quoteTable,
      userName,
      currProspectData,
      quoteStatusCarrier,
      viewQuoteDisable,
      isHistoryVariant,
    } = this.props;
    const { ocrDisableBind, showOcrStatus, parseStatus, parseStatusCss } =
      this.state;
    return (
      <>
        {showOcrStatus && (
          <div id={parseStatusCss} className="mb-3">
            {parseStatus}
          </div>
        )}
        <div className="col-sm-12 text-center">
          <button
            onClick={() =>
              this.quoteView(
                quoteTable,
                currProspectData,
                userName,
                quoteStatusCarrier
              )
            }
            disabled={viewQuoteDisable}
            className={
              viewQuoteDisable
                ? "view-quote btn btn-secondary btnView mt-0"
                : "view-quote btn btn-primary btnView mt-0"
            }
          >
            VIEW QUOTE
          </button>
          <button
            onClick={() =>
              proceedBind2(
                quoteTable,
                userName,
                currProspectData,
                isHistoryVariant
              )
            }
            className="btn btn-primary btnSubmits mt-0"
            disabled={viewQuoteDisable}
          >
            CONTINUE
          </button>
          <hr />
        </div>
      </>
    );
  }
}

export default BindQuote;
