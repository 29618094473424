import { MapControl, withLeaflet } from "react-leaflet";
import L from "leaflet";

class Legend extends MapControl {
  createLeafletElement(props) {}

  componentDidMount() {
    // get color depending on population density value
    const getColor = d => {
        return(
           d > 10000000 || (d > 70 && d < 81)
        ? "#012333"
        : d > 5000000 || (d > 60 && d < 71)
          ? "#00344D"
          : d > 1000000 || (d > 50 && d < 61)
            ? "#014666"
            : d > 500000 || (d > 40 && d < 51)
              ? "#005A9E"
              : d > 100000 || (d > 30 && d < 41)
                ? "#01608C"
                : d > 50000 || (d > 20 && d < 31)
                  ? "#0272A6"
                  : d > 10000 || (d > 10 && d < 21)
                    ? "#03A9F4"
                    : "lightgrey"
        )
    };

    const legend = L.control({ position: "bottomright" });

    legend.onAdd = () => {
      
      const div = L.DomUtil.create("div", "info legend");
      const grades = [0, 10000, 50000, 100000, 500000,1000000,5000000,10000000];
      let labels = [];
      let labels2 =[]
      let from;
      let to;

      for (let i = 0; i < grades.length; i++) {
        from = grades[i];
        to = grades[i + 1];

        labels.push(
          '<i style="background:' +
            getColor(from + 1) +
            '"></i> ' +
            from +
            (to ? "&ndash;" + to : "+")
        );
      }
      labels2.push('<i style="background:#6cdef5"></i>')
      labels2.push('<i style="background:#03A9F4"></i>')
      labels2.push('<i style="background:#005A9E"></i>')
      labels2.push('<i style="background:#00344D"></i>')

      div.innerHTML = labels2.join("");
      div.innerHTML += "</br>"
      div.innerHTML += "<label>Low</label>"
      div.innerHTML += "<x>High</x>"

      return div;
    };
   

    const { map } = this.props.leaflet;
    legend.addTo(map);
  }
}

export default withLeaflet(Legend);
