import React, { useState } from "react";
import { Tab, Col, Row, Nav } from "react-bootstrap";
import FormCompPro from "../../component/subcompo/sections-quote/FormCompPro";
import FormCompareRate from "../../component/subcompo/sections-quote/FormCompareRate";
import FormUnderQues from "../../component/subcompo/sections-quote/FormUnderQues";
import FormCmprQuote from "../../component/subcompo/sections-quote/FormCmprQuote";
import FormPayment from "../../component/subcompo/sections-quote/FormPayment";
import Binder from "../../component/subcompo/sections-quote/Binder";
import { versionNumber } from "../../config";

const stages = {
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: 5,
  six: 6,
};

function TabNavItem(props) {
  let currProspect = sessionStorage.getItem("currProspect");
  let isHistoryVariant;
  if (currProspect) {
    isHistoryVariant = JSON.parse(currProspect).historicalQuote;
  }
  return (
    <Nav.Item>
      <Nav.Link
        eventKey={props.eventKey}
        className={props.disabled ? "disabled" : ""}
      >
        <div className="d-flex flex-row align-items-center">
          <img src={props.image} width="50" height="50" alt={props.label} />
          <p
            className={
              "sidebar-stagename mb-0 " + (props.active ? "active" : "")
            }
          >
            {props.label}
          </p>
          {props.dash && (
            <p className={"ml-auto mr-2 mb-3 tick"}>
              <img src={require("../../images/tick_bw.png")} />
            </p>
          )}
          {!props.dash && (
            <p
              className={
                "ml-auto mr-2 mb-3 " + (props.completed ? "tick" : "disabled")
              }
            >
              <img src={require("../../images/tick.png")} />
            </p>
          )}
        </div>
      </Nav.Link>
    </Nav.Item>
  );
}

function SideTab(props) {
  let formStage = sessionStorage.getItem("formStage");
  let currProspect = sessionStorage.getItem("currProspect");

  let isHistoryVariant;
  if (currProspect) {
    isHistoryVariant = JSON.parse(currProspect).historicalQuote;
  }
  if (!formStage) {
    formStage = "one";
  }
  const tabLinks = [
    { label: "Company Profile", image: require("../../images/comPro2.png") },
    { label: "Compare Rate", image: require("../../images/compRate2.png") },
    {
      label: "Underwriting Questions",
      image: require("../../images/underQues2.png"),
    },
    { label: "Get Quotes", image: require("../../images/getQuotes2.png") },
    { label: "Review and Pay", image: require("../../images/reviewPay2.png") },
    { label: "Bind", image: require("../../images/bind2.png") },
  ];

  let stageNumber = stages[formStage];
  for (let i = 0; i < tabLinks.length; i++) {
    let tabLink = tabLinks[i];
    tabLink.eventKey = Object.keys(stages)[i];
    // Disable if this stage is later than the current stage.
    tabLink.disabled = i >= stageNumber;
    // Completed if stateNuber is more than this stage.
    tabLink.completed = stageNumber > i + 1;
    if (isHistoryVariant) {
      if (i < 4) {
        tabLink.disabled = true;
        tabLink.completed = false;
        tabLink.dash = true;
      }
    }
    // Active tab if stageNumber equals this stage.
    tabLink.active = stageNumber === i + 1;
  }

  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey={formStage}>
      <div className="row no-gutters">
        <Col
          md="auto"
          className="newquote-sidebar d-flex flex-column justify-content-between"
        >
          <Nav className="flex-column">
            <Nav.Item>
              <Nav.Link className="sidebar-heading" disabled>
                <span>
                  Complete the process in a few easy steps and get your quote
                </span>
              </Nav.Link>
            </Nav.Item>
            {tabLinks.map((tabLink) => (
              <TabNavItem {...tabLink} />
            ))}
          </Nav>
          <div className="ml-4">Version {versionNumber}</div>
        </Col>
        <Col className="newquote-content">
          <Tab.Content>
            <Tab.Pane eventKey="one">
              <FormCompPro />
            </Tab.Pane>
            <Tab.Pane eventKey="two">
              <FormCompareRate />
            </Tab.Pane>
            <Tab.Pane eventKey="three">
              <FormUnderQues />
            </Tab.Pane>
            <Tab.Pane eventKey="four">
              <FormCmprQuote />
            </Tab.Pane>
            <Tab.Pane eventKey="five">
              <FormPayment />
            </Tab.Pane>
            <Tab.Pane eventKey="six">
              <Binder />
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </div>
    </Tab.Container>
  );
}

export default SideTab;
