import one from "../../images/homepage//process/1.svg";
import two from "../../images/homepage//process/2.svg";
import three from "../../images/homepage//process/3.svg";
import styles from "./Process.module.css";
import pageStyles from "../pages/NewHome.module.css";
import React, { Component } from "react";

export default function Process() {
  const columnContents = [
    {
      heading: "Compare Rates",
      image: two,
      imageClass: "two",
      paraText: `Our ‘live’ rating tool helps you compare rates instantly at a state and class code level, giving you ball-park estimates with very minimal input.`,
    },
    {
      heading: "Get quotes",
      image: three,
      imageClass: "three",
      paraText: `With little more info, our quote engine will provide you full-blown ‘bindable’ quotes from all interested carriers, allowing you to choose most preferred option.`,
    },
    {
      heading: "Final bind",
      image: one,
      imageClass: "one",
      paraText: `Our integrated platform allows you to electronically upload all required documents and get your binder instantly from your preferred carrier.`,
    },
  ];
  return (
    <div className={pageStyles.sectionContainer + " " + styles.processMain}>
      <div className="row">
        <div className="col-12 mb-5">
          <h2 className={styles.text}>Easy &amp; simple process</h2>
        </div>
        {columnContents.map((columnContent, i) => {
          return (
            <div className="col-md-4 col-xs-12 mb-4" key={i}>
              <div className={styles.processBox}>
                <div
                  className={
                    styles.imageBox +
                    " d-flex flex-row justify-content-left align-items-center"
                  }
                >
                  <img
                    alt={columnContent.heading}
                    className={styles[columnContent.imageClass]}
                    src={columnContent.image}
                  />
                </div>
                <h3 className={styles.text}>{columnContent.heading}</h3>
                <p className={styles.smalltext}>{columnContent.paraText}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
