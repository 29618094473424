import React, { useContext } from "react";
import ContactForm from "./ContactForm";
import { Modal } from "react-bootstrap";
import { ContactFormContext } from "./ContactFormContext";

export default function ContactPopup() {
  const { showForm, setShowForm } = useContext(ContactFormContext);

  return (
    <Modal show={showForm} onHide={() => setShowForm(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Get a Quote</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ContactForm
          onFormSubmitted={() => {
            setShowForm(false);
          }}
        />
      </Modal.Body>
    </Modal>
  );
}
