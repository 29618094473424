import React, { Component } from "react";
import { clearErr } from "../../utils/common";
var stateCity = require("../../utils/state_city.json");

class StateList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const stateList = [
      <option key={0} value="">
        State
      </option>
    ];
    const { stateToSelect } = this.props;
    for (var item in stateCity) {
      if (item === stateToSelect) {
        stateList.push(
          <option key={item} value={item} selected>
            {item}
          </option>
        );
      } else {
        stateList.push(
          <option key={item} value={item}>
            {item}
          </option>
        );
      }
    }
    return (
      <select name="stateCodes" onChange={clearErr}>
        {stateList}
      </select>
    );
  }
}
export default StateList;
