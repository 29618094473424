import React, { Component } from "react";
import axios from "axios";
import {
  deleteAllCookies,
  showErrAlert,
  blobPdfFromBase64String,
} from "../../../utils/common";
import { awsUrl } from "../../../config";
import { Auth } from "aws-amplify";
import $ from "jquery";
import QuoteTable from "../../common/QuoteTable";
import NewProgress from "../../../newcomponent/common/NewProgress";

class FormCmprQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      visible: false,
      uuid_carrier: [],
    };
    this.QuoteView = this.QuoteView.bind(this);
  }
  componentWillMount() {
    if (!window.location.hash) {
      window.location = window.location + "#loaded";
      window.location.reload();
    }
  }
  componentDidMount() {
    $("html, body").animate({ scrollTop: 0 }, 250);
    var formStage = sessionStorage.getItem("formStage");
    console.log("Form stage: ", formStage);
    if (formStage === "four" || formStage === "five" || formStage === "six") {
      $("#loader").css("display", "block");
      let currProspectDetails = JSON.parse(
        sessionStorage.getItem("currProspect")
      );
      if (currProspectDetails !== null) {
        let user_email = currProspectDetails.emailAddress;
        Auth.currentSession().then((res) => {
          const USER_TOKEN = res.idToken.jwtToken;
          const header = { headers: { Authorization: "Bearer " + USER_TOKEN } };
          axios
            .get(
              awsUrl + "/api/getUserDataStatus/" + user_email + "/quote",
              header
            )
            .then((response) => {
              console.log("response checking:", response);
              this.setState({
                uuid_carrier: response.data.uuid,
                data: response.data.carrierBasedData,
              });
              $("#loader").css("display", "none");
            })
            .catch((err) => {
              showErrAlert(err);
              $("#loader").css("display", "none");
            });
        });
      } else {
        sessionStorage.setItem("formStage", "one");
        deleteAllCookies();
        sessionStorage.clear();
        window.location.reload();
      }
    }
  }

  QuoteView(event) {
    let carrierTemplate = event.target.id.split("#+");
    let carrier = carrierTemplate[0];
    let timestamp = carrierTemplate[1];
    let getQuoteDetails = {};
    let email = JSON.parse(sessionStorage.getItem("currProspect")).emailAddress;
    getQuoteDetails.email = email;
    getQuoteDetails.timestamp = timestamp;
    getQuoteDetails.carrier = carrier;
    Auth.currentSession().then((res) => {
      const USER_TOKEN = res.idToken.jwtToken;
      const header = { headers: { Authorization: "Bearer " + USER_TOKEN } };
      axios
        .post(
          awsUrl + "/api/generateQuotePDF",
          JSON.stringify(getQuoteDetails),
          header
        )
        .then((res) => {
          let pdf = res.data.data;
          let fileName = "Quote.pdf";
          let file = blobPdfFromBase64String(pdf);
          var fileURL = URL.createObjectURL(file);
          let newWindow = window.open("./QuotePDF", "_blank");
          newWindow.onload = () => {
            newWindow.location = fileURL;
          };
        })
        .catch((err) => {
          console.log("Err", err);
          showErrAlert("View Quote is not available due to error");
        });
    });
  }

  render() {
    return (
      <div id="cmprQts" className="container-fluid per98">
        <NewProgress />
        <div id="compareQuote">
          <QuoteTable data={this.state.data} />
        </div>
      </div>
    );
  }
}
export default FormCmprQuote;
