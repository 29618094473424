import React, { Component } from "react";
import ReactDOM from "react-dom";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import LoginForm from "../subcompo/sections-login/LoginForm";

class Login extends Component {
  componentDidMount() {
    ReactDOM.findDOMNode(this).scrollTop = 0;
  }
  render() {
    return (
      <div>
        <Navbar variant="large-logo" />
        <LoginForm />
        <Footer />
      </div>
    );
  }
}

export default Login;
