import React, { Component } from "react";
import ReactDOM from "react-dom";
import NumberFormat from "react-number-format";
import { Form } from "react-bootstrap";
import {
  submitCompPro,
  stepOneGet,
  sendEmail,
  resendOTPMethod,
  verifyOTP,
  reset,
  setStateAndCity,
} from "../../../utils/form_compPro";
import {
  clearErr,
  validEmail,
  onlyAlpha,
  clearDelete,
  initializeComponents,
} from "../../../utils/common";
import {
  cookie,
  cookie2,
  setCookie,
  setProspectDetails,
  deleteAddress,
  setAddressDetails,
} from "../../../utils/session_data";
import NewProgress from "../../../newcomponent/common/NewProgress";
import $ from "jquery";
import SmallLoader from "../../common/SmallLoader";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiURL, awsUrl } from "../../../config";
import store from "../../../redux/store/index";
import StateList from "../../common/StateList";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { Auth } from "aws-amplify";
import AWS from "aws-sdk";
let myConfig = new AWS.Config();
myConfig.update({
  region: "us-east-1",
  credentials: {
    accessKeyId: "AKIAJB3XUTJTLJMI4BHA",
    secretAccessKey: "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",
  },
});
var prospect, addressCount, loggedin;
class FormCompPro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      role: [],
      selDateBus: null,
      selDateEff: moment(),
      selDateExp: moment().add(1, "year"),
      header: {},
      childrenLoc: {},
      uploadingFile: false,
      awaitingFile: false,
      error: false,
      errorMsg: "",
      urlSuccess: false,
      urlFail: false,
      basicInfoFail: false,
      stateInfoFail: false,
      signedUrl: "",
    };
  }

  addYearToDate = (dt) => {
    dt.exp = Object.assign({}, dt);
    dt.exp.eff.add(1, "year");
    return dt;
  };
  handleDateChangeBus = (date) => {
    this.setState({
      selDateBus: date,
    });
  };
  handleDateChangeEff = (date) => {
    if (date) {
      let y = moment(date._d).add(1, "year");
      this.setState({
        selDateEff: date,
        selDateExp: y,
      });
    }
  };
  handleDateChangeExp = (date) => {
    this.setState({
      selDateExp: date,
    });
  };
  componentWillMount() {
    window.scrollTo(0, 0);
    stepOneGet();
    prospect = cookie();
    addressCount = cookie2();
    loggedin = sessionStorage.getItem("isLoggedIn");
    let rand = Math.random();
    let dupChildrenLoc = {};
    let initialCountOfAddress = 1;
    if (prospect && addressCount && loggedin) {
      initialCountOfAddress = addressCount;
    }
    for (let i = 0; i < initialCountOfAddress; i++) {
      let rand = Math.random();
      dupChildrenLoc[i] = (
        <CompLoc key={rand} number={i} rmvChild={this.onrmvChild} />
      );
    }
    this.setState({
      childrenLoc: dupChildrenLoc,
    });
    $("#loader").css("display", "block");
  }
  componentDidUpdate() {
    const { childrenLoc } = this.state;
    clearDelete(childrenLoc, "addressBlock");
    if (prospect && addressCount && loggedin) {
      setProspectDetails();
      setAddressDetails();
    }
  }
  componentDidMount() {
    $("html, body").animate({ scrollTop: 0 }, 250);
    $("#loader").css("display", "block");
    var curre = Auth.currentSession()
      .then((res) => {
        let userRole = res.accessToken.payload["cognito:groups"];
        let userName = res.accessToken.payload.username.split("@")[0];
        let email = res.accessToken.payload.username;
        const USER_TOKEN = res.idToken.jwtToken;
        this.setState({
          role: userRole,
          username: userName,
        });

        if (email && loggedin && userRole.includes("User")) {
          const header = { headers: { Authorization: "Bearer " + USER_TOKEN } };
          axios
            .get(awsUrl + "/api/getProspectsData/" + email, header)
            .then(async function (response) {
              if (response.status === 200) {
                let acordData = JSON.parse(sessionStorage.getItem("acordData"));
                let currProspect = JSON.parse(
                  sessionStorage.getItem("currProspect")
                );

                if (acordData) {
                  if (!currProspect.prospectAddresses[0].classCodes) {
                    let state_details = acordData.state_details;
                    // let currProspect = {};
                    for (let row of state_details) {
                      let index = parseInt(row["LOC"]) - 1;
                      if (row["LOC"] in acordData.location_details) {
                        if (!currProspect.prospectAddresses[index]) {
                          currProspect.prospectAddresses[index] = {};
                        }
                        if (!currProspect.prospectAddresses[index].classCodes) {
                          currProspect.prospectAddresses[index].classCodes = [];
                        }
                        let remuneration = row["REMUNERATION"];
                        if (remuneration) {
                          let dollarSplit = remuneration.split("$");
                          if (dollarSplit.length > 1) {
                            remuneration = dollarSplit[1].trim();
                          } else {
                            remuneration = dollarSplit[0].trim();
                          }
                          remuneration = remuneration
                            .split(" ")[0]
                            .split(".")[0]
                            .split(",")
                            .join("");
                        } else {
                          remuneration = "0";
                        }
                        let ccObj = {
                          code: row["CLASS"],
                          // payroll: "50000",
                          payroll: remuneration,
                          pt: row["PART"],
                          ft: row["FULL"],
                        };
                        currProspect.prospectAddresses[index].classCodes.push(
                          ccObj
                        );
                      }
                      // currProspect.prospectAddresses[index].classCodes.push(
                      //   ccObj
                      // );
                      // console.log("++", row);
                    }
                    // console.log(currProspect);
                    // let prospectAddresses = {
                    //   prospectAddresses: currProspect
                    // };
                    currProspect.formStage = "two";
                    sessionStorage.setItem(
                      "currProspect",
                      JSON.stringify(currProspect)
                    );

                    // for (let data in currProspect.prospectAddresses) {
                    //   for (let data2 of currProspect.prospectAddresses[data]
                    //     .classCodes) {
                    //     console.log("+++", data2);
                    //     currProspect.prospectAddresses[data].classCodes.push(
                    //       data2
                    //     );
                    //     break;
                    //   }
                    // }
                  }
                  response.data = currProspect;

                  // sessionStorage.setItem("reload", "True");
                }
                // sessionStorage.removeItem("acordData");
                console.log("1st page 200", response.data);
                for (
                  let i = 0;
                  i < response.data.prospectAddresses.length;
                  i++
                ) {
                  response.data.prospectAddresses[i].classCodes = Object.assign(
                    {},
                    response.data.prospectAddresses[i].classCodes
                  );
                }
                response.data.prospectAddresses = Object.assign(
                  {},
                  response.data.prospectAddresses
                );
                try {
                  let isFormStageNull = sessionStorage.getItem("formStage");
                  sessionStorage.setItem(
                    "currProspect",
                    JSON.stringify(response.data)
                  );
                  sessionStorage.setItem("formStage", response.data.formStage);
                  setCookie(
                    response.data.prospectId,
                    Object.values(response.data.prospectAddresses).length,
                    1
                  );
                  if (
                    response.data.require !== null &&
                    response.data.require !== undefined &&
                    (response.data.formStage === "five" ||
                      response.data.formStage === "six")
                  ) {
                    sessionStorage.setItem("require", response.data.require);
                  }

                  if (isFormStageNull === null) {
                    window.location.reload();
                  }
                  try {
                    store.dispatch(window.updateDetails(response.data));
                  } catch (e) {
                    console.log(e.message);
                  }
                  store.dispatch(window.updateDetails(response.data));
                  let acordData = JSON.parse(
                    sessionStorage.getItem("acordData")
                  );
                  let reload = sessionStorage.getItem("reload");

                  if (
                    acordData &&
                    response.data.formStage == "two" &&
                    !reload
                  ) {
                    console.log(currProspect);
                    sessionStorage.setItem(
                      "currProspect",
                      JSON.stringify(currProspect)
                    );
                    sessionStorage.setItem("reload", "true");
                    window.location.reload();
                  }
                  // if (acordData && response.data.formStage) {

                  // }
                } catch (e) {
                  console.log(e.message);
                }
              } else {
                console.log("1st page else");
                try {
                  let responseSession = JSON.parse(
                    sessionStorage.getItem("currProspect")
                  );
                  store.dispatch(window.updateDetails(responseSession));
                } catch (e) {
                  console.log(e.message);
                }
              }
              if (response.status === 204) {
                $("#loader").css("display", "none");
              }
            })
            .catch(function (e) {
              try {
                let responseSession = JSON.parse(
                  sessionStorage.getItem("currProspect")
                );
                store.dispatch(window.updateDetails(responseSession));
              } catch (e) {
                console.log(e.message);
              }
            })
            .then(() => {
              setProspectDetails();
              setAddressDetails();
              loggedin = sessionStorage.getItem("isLoggedIn");
              this.forceUpdate();
            });
        }
      })
      .catch((err) => {
        $("#loader").css("display", "none");
        this.setState({
          role: [],
          username: "",
        });
      });
    // if(sessionStorage.getItem('currProspect') !== undefined && sessionStorage.getItem('currProspect') !== null){

    // }
    // if(prospect && addressCount && loggedin){
    //     $('#loader').css('display','block');
    //     console.log("Current auth user: ", curre);
    //     let user_email_id = JSON.parse(sessionStorage.getItem('currProspect')).emailAddress;
    //     axios.get(awsUrl+'/api/getProspectsData/'+user_email_id).then(function(response){

    //         if(response.status===200){
    //             try{
    //                 console.log("Prospect data: ", response.data);
    //                 let carrierValues = store.getState().carrierDetails;
    //                 console.log("carrierValues:", carrierValues);
    //                 store.dispatch(window.updateDetails(response.data));
    //             }catch(e){
    //                 console.log(e.message);
    //             }
    //         }else{

    //             try{

    //                 let responseSession = JSON.parse(sessionStorage.getItem('currProspect'))
    //                 store.dispatch(window.updateDetails(responseSession));
    //             }catch(e){
    //                 console.log(e.message);
    //             }
    //         }
    //     }).catch(function(e){
    //         // showErrAlert(e);
    //             console.log(e)
    //             try{
    //                 let responseSession = JSON.parse(sessionStorage.getItem('currProspect'))
    //                 console.log("response details: ",responseSession);
    //                     store.dispatch(window.updateDetails(responseSession));
    //             }catch(e){
    //                 // showErrAlert(e);
    //                 console.log(e.message);
    //             }
    //     }).then(function(){
    //         setProspectDetails();
    //         setAddressDetails();
    //         loggedin = sessionStorage.getItem('isLoggedIn');
    //     });
    // }
    // console.log("Logged in patter:", loggedin);
    //      if(!loggedin || loggedin === null){
    //         $('#existUser').css('display','block');
    //         $('#email-form').css('display', 'block');
    //         $('#existUserName').css('display','none');
    //      }else{
    //          $('#existUserName').css('display','block');
    //          $('#existUser').css('display','none');
    //         //  $('#passwordField').css('display', 'none');
    //         //  $('#confirmPassword').css('display', 'none');
    //          $('#email-form').css('display', 'none');
    //      }
    // $("#companyName").val("company");
    // console.log("+++++", $("#companyName").text());
    let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
    let businessDate, effDate, expDate;
    try {
      businessDate = moment(currProspect.businessStartDate);
      effDate = moment(currProspect.effectiveDate);
      expDate = moment(currProspect.expirationDate);
    } catch (e) {
      businessDate = null;
      effDate = moment();
      expDate = moment().add(1, "year");
    }
    this.setState({
      selDateBus: businessDate,
      selDateEff: effDate,
      selDateExp: expDate,
    });
  }

  handleFileUpload = (event) => {
    this.setState({ file: event.target.files });
  };

  autoFillAcordData = (data, that) => {
    console.log("data", data);
    sessionStorage.setItem("acordData", JSON.stringify(data));
    const { basic_information, state_details, location_details } = data;
    if (
      basic_information.eff_date &&
      moment(basic_information.eff_date).isValid()
    ) {
      this.setState({ selDateEff: moment(basic_information.eff_date) });
    }
    if (
      basic_information.exp_date &&
      moment(basic_information.exp_date).isValid()
    ) {
      this.setState({ selDateExp: moment(basic_information.exp_date) });
    }
    if (basic_information.fein) {
      this.setState({ fein: basic_information.fein });
    }
    if (basic_information.company_name) {
      this.setState({ company_name: basic_information.company_name });
    }
    if (basic_information.phone_number) {
      this.setState({ phone_number: basic_information.phone_number });
    }
    // if (basic_information.desc) {
    //   this.setState({ desc: basic_information.desc });
    // }
    // let vals = {
    //   "#companyName": "company_name",
    //   "#phoneNumber": "phone_number",
    //   "#federalEmployeeId": "fein",
    //   "#description": "desc"
    // };

    if (basic_information.desc) {
      $("#description").val(basic_information.desc);
    }
    // for (let val in vals) {
    //   $(val).val(basic_information[vals[val]]);
    // }

    if (Object.entries(location_details).length > 0) {
      for (let locNumber in location_details) {
        that.onAddChildLoc(location_details[locNumber]);
      }
      that.onrmvChild(0);
    }

    // console.log(state_details);

    // let currProspect = {};
    // for (let row of state_details) {
    //   let index = parseInt(row["LOC"]) - 1;
    //   if (!currProspect[index]) {
    //     currProspect[index] = {};
    //   }
    //   if (!currProspect[index].classCodes) {
    //     currProspect[index].classCodes = [];
    //   }
    //   console.log("++", row);
    //   let ccObj = {
    //     code: row["CLASS"],
    //     payroll: row["REMUNERATION"].split(" ").reverse()[0],
    //     pt: row["PART"],
    //     ft: row["FULL"],
    //     desc: row["DESC"]
    //   };
    //   currProspect[index].classCodes.push(ccObj);
    //   // console.log("++", row);
    // }
    // console.log(currProspect);
    // let prospectAddresses = {
    //   prospectAddresses: currProspect
    // };
    // sessionStorage.setItem("currProspect", JSON.stringify(prospectAddresses));
    // for (let row in state_details) {
    //   prospect = {}
    //   console.log();
    // }
  };

  uploadFile = (event) => {
    if (this.state.file) {
      var s3 = new AWS.S3(myConfig);
      // $("#loader").css("display", "block");
      this.setState({ uploadingFile: true });
      this.setState({
        error: false,
        urlSuccess: false,
        urlFail: false,
        basicInfoFail: false,
        stateInfoFail: false,
        signedUrl: "",
        awaitingFile: false,
      });

      console.log("Clicked", this.state.file[0]);
      const that = this;
      event.preventDefault();

      const formData = new FormData();
      formData.append("file", this.state.file[0]);

      axios
        .post(
          "https://5swuplkv50.execute-api.us-east-1.amazonaws.com/dev/api/fileUploadAcord",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          console.log(response);

          that.setState({ uploadingFile: false });
          that.setState({ awaitingFile: true });
          const fileName = response.data.fileName;
          const folder = fileName.split(".")[0];
          const resultFilePath = `${folder}/finalResponse.json`;
          console.log("++succ", resultFilePath);
          var params = {
            Bucket: "ocr-doc-demo-acord",
            Key: resultFilePath,
            $waiter: {
              maxAttempts: 180,
              delay: 5,
            },
          };
          var getParams = {
            Bucket: "ocr-doc-demo-acord",
            Key: resultFilePath,
          };
          s3.waitFor("objectExists", params, function (err, data) {
            if (err) {
              console.log("file not yet ready", err);
              that.setState({ awaitingFile: false });
              that.setState({ error: true, errorMsg: "File not yet ready!" });
            } else {
              console.log("file created successfully", data); // successful response
              that.setState({ awaitingFile: false });
              s3.getSignedUrl("getObject", getParams, function (err, url) {
                if (err) {
                  $("#loader").css("display", "none");
                  console.log("Error", err);
                  that.setState({
                    error: true,
                    errorMsg: "Error in retreiving the file",
                  });
                } else {
                  console.log("The URL is", url);
                  fetch(url)
                    .then((response) => response.json())
                    .then((data) => {
                      console.log("++++data", data);
                      if (Object.entries(data).length === 0) {
                        that.setState({ urlFail: true });
                      } else if (
                        Object.entries(data.basic_information).length === 0 &&
                        Object.entries(data.location_details).length !== 0
                      ) {
                        that.setState({ basicInfoFail: true });
                        that.autoFillAcordData(data, that);
                      } else if (
                        Object.entries(data.basic_information).length !== 0 &&
                        Object.entries(data.location_details).length === 0
                      ) {
                        that.setState({ stateInfoFail: true });
                        that.autoFillAcordData(data, that);
                      } else if (
                        Object.entries(data.basic_information).length === 0 &&
                        Object.entries(data.location_details).length === 0
                      ) {
                        that.setState({ urlFail: true });
                      } else {
                        that.setState({ urlSuccess: true, signedUrl: url });
                        that.autoFillAcordData(data, that);
                      }

                      // let parsedObject = JSON.parse(stringifiedData);
                      // console.log("data", stringifiedData);
                      $("#loader").css("display", "none");
                    })
                    .catch((error) => {
                      console.log(`Failed because: ${error}`);
                      $("#loader").css("display", "none");
                    });
                }
              });
            }
          });
        })
        .catch((error) => {
          $("#loader").css("display", "none");
          this.setState({ uploadingFile: false });
          console.log("++error", error);
          // handle your error
        });
    }
  };

  render() {
    // const childrenLoc = [];
    const { childrenLoc } = this.state;
    const {
      uploadingFile,
      awaitingFile,
      urlSuccess,
      urlFail,
      basicInfoFail,
      stateInfoFail,
      signedUrl,
      selDateBus,
      selDateEff,
      selDateExp,
    } = this.state;
    // [<CompLoc key={1} number={1} rmvChild={this.onrmvChild} />];
    // for (var i = 0; i < numChildrenLoc; i += 1) {
    // let rand = Math.random();
    // childrenLoc.push(
    //   <CompLoc key={rand} number={i} rmvChild={this.onrmvChild} />
    // );
    // }
    var isLoggedIn =
      sessionStorage.getItem("isLoggedIn") === undefined ||
        sessionStorage.getItem("isLoggedIn") === null
        ? false
        : sessionStorage.getItem("isLoggedIn");
    let isFormStageNull = sessionStorage.getItem("formStage");
    return (
      <div id="compPro" className="container-fluid active per98">
        <NewProgress />
        <div id="compProfile">
          <div>
            {/* <form className="col-md-12">
              <span className="formTitle">Pre-fill data from acord form</span>
              <hr />
              <div className="form-group">
                <input
                  label="upload file"
                  type="file"
                  onChange={this.handleFileUpload}
                />
              </div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.uploadFile}
              >
                Submit
              </button>
            </form> */}
            <div className="col-md-12 mt-2">
              {uploadingFile && (
                <span>
                  Uploading file <SmallLoader />
                </span>
              )}
              {awaitingFile && (
                <span>
                  Processing Acord Form <SmallLoader />
                </span>
              )}
              {urlSuccess && (
                <div>
                  <a href={signedUrl}>Acord data has been autofilled</a>
                </div>
              )}
              {urlFail && (
                <div>
                  <span>
                    Your ACORD template is currently not configured for OCR.
                    Please contact admin@insurecomp.com for more information.
                  </span>
                </div>
              )}
              {basicInfoFail && (
                <div>
                  <span>
                    State info has been autofilled. Basic info could not be
                    parsed.
                  </span>
                  {/* <hr /> */}
                </div>
              )}
              {stateInfoFail && (
                <div>
                  <span>
                    Basic info has been autofilled. State info could not be
                    parsed.
                  </span>
                  {/* <hr /> */}
                </div>
              )}
              {/* <hr /> */}
            </div>
          </div>
          <form>
            {!isLoggedIn && (
              <p id="existUser">
                Already an existing user?{" "}
                <Link className="prominent" to={"/Login"}>
                  Sign In
                </Link>
              </p>
            )}
            {/* {isLoggedIn && (
              <p id="existUserName">
                Welcome, <span className="prominent" /> {this.state.username}
              </p>
            )} */}
          </form>
          <form>
            {/* {!isLoggedIn &&  */}

            {/* } */}
            {/* {!isLoggedIn && <div> */}
            <div className="formTitle">
              <h3>Let's start with your company profile</h3>
            </div>
            {/* <hr /> */}
            <div className="row">
              <div className="col-sm-6 grey">
                <Form.Group>
                  <label for="companyName" className="mandatory">
                    Company Name
                  </label>
                  <input
                    defaultValue={
                      this.state.company_name ? this.state.company_name : ""
                    }
                    id="companyName"
                    type="text"
                    onChange={clearErr}
                    autoComplete="off"
                    className="rectangle1"
                  />
                  <p className="errMsg" />
                </Form.Group>
                <div className="row">
                  <div className="col-6">
                    <Form.Group>
                      <label for="phoneNumber" className="mandatory">
                        Phone Number
                      </label>
                      <NumberFormat
                        defaultValue={
                          this.state.phone_number ? this.state.phone_number : ""
                        }
                        autoComplete="off"
                        id="phoneNumber"
                        format="(###) ###-####"
                        type="text"
                        onChange={clearErr}
                      />
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group>
                      <label
                        for="contactPerson"
                        title="Primary Contact (Name)"
                        className="mandatory"
                      >
                        Primary Contact (Name)
                      </label>
                      <input
                        id="contactPerson"
                        type="text"
                        // onKeyUp={onlyAlpha}
                        onChange={clearErr}
                        autoComplete="off"
                      />
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group>
                      <label for="federalEmployeeId" className="mandatory">
                        FEIN
                      </label>
                      <NumberFormat
                        defaultValue={this.state.fein ? this.state.fein : ""}
                        autoComplete="off"
                        id="federalEmployeeId"
                        format="##-#######"
                        type="text"
                        onChange={clearErr}
                      />
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                  {/* <div className="col-sm-6">
                    Entity Type<span className="mandate">*</span>
                    <span className="errMsg" />
                    <select id="businessTypeId" onChange={clearErr} />
                  </div> */}
                  {/* <div className="col-sm-6">
                    Employer's Liability Limit<span className="mandate">*</span>
                    <span className="errMsg" />
                    <select id="liabilityLimitId" onChange={clearErr} />
                  </div> */}
                </div>
              </div>
              <div className="col-sm-6 grey">
                <Form.Group>
                  <label className="mandatory" for="description">
                    Description of Operations
                  </label>
                  <textarea
                    id="description"
                    className="rectangle1"
                    rows="7"
                    onChange={clearErr}
                  />
                  <p className="errMsg" />
                </Form.Group>
              </div>
              <div className="col-sm-12 grey">
                <div className="row">
                  <div className="col-sm-3">
                    <Form.Group>
                      <label
                        for="businessTypeId"
                        title="Entity Type"
                        className="mandatory"
                      >
                        Entity Type
                      </label>
                      <select id="businessTypeId" onChange={clearErr} />
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                  <div className="col-sm-3">
                    <Form.Group>
                      <label
                        for="liabilityLimitId"
                        title="Employer's Liability Limit"
                        className="mandatory"
                      >
                        Employer's Liability Limit
                      </label>
                      <select id="liabilityLimitId" onChange={clearErr} />
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                  <div className="col-sm-2">
                    <Form.Group>
                      <label
                        for="businessStartDate"
                        title="Business Start Date"
                        className="mandatory"
                      >
                        Business Start Date
                      </label>
                      <span id="businessStartDate">
                        <DatePicker
                          selected={selDateBus}
                          maxDate={selDateEff}
                          onChange={this.handleDateChangeBus}
                          showYearDropdown
                          showMonthDropdown
                          useShortMonthInDropdown
                        />
                      </span>
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                  <div className="col-sm-2">
                    <Form.Group>
                      <label
                        for="effectiveDate"
                        title="Effective Date"
                        className="mandatory"
                      >
                        Effective Date
                      </label>
                      <span id="effectiveDate">
                        <DatePicker
                          selected={selDateEff}
                          onChange={this.handleDateChangeEff}
                          showYearDropdown
                          showMonthDropdown
                          useShortMonthInDropdown
                        />
                      </span>
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                  <div className="col-sm-2">
                    <Form.Group>
                      <label
                        for="expirationDate"
                        title="Expiration Date"
                        className="mandatory"
                      >
                        Expiration Date
                      </label>
                      <span id="expirationDate">
                        <DatePicker
                          minDate={selDateEff}
                          selected={selDateExp}
                          onChange={this.handleDateChangeExp}
                          showYearDropdown
                          showMonthDropdown
                          useShortMonthInDropdown
                        />
                      </span>
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
            {!isLoggedIn && (
              <div className="row" id="email-form">
                <div className="col-sm-3 relDiv">
                  <Form.Group>
                    <label for="emailAddress" className="mandatory">
                      Email Address
                    </label>
                    <input
                      id="emailAddress"
                      type="email"
                      onBlur={validEmail}
                      onChange={clearErr}
                      autoComplete="off"
                    />
                    <p className="errMsg mb-0" />
                    <p id="otpSendMsg" className="mb-0">
                      Sending a verification code to your email address.
                    </p>
                    <p id="otpSentMsg" className="mb-0">
                      A 6-digit verification code has been sent to your email.
                    </p>
                    <p id="otpVerify" className="mb-0">
                      Verification has been started.
                    </p>
                    <p id="verifySuc" className="mb-0">
                      Verification is successful.
                    </p>
                  </Form.Group>
                </div>
                <div className="col-sm-3 relDiv">
                  <Form.Group>
                    <button
                      id="emailStatus"
                      type="button"
                      className="btn btn-outline-primary btn-sm"
                      onClick={sendEmail}
                    >
                      Confirm
                    </button>
                    <div id="otpSec">
                      <div className="row">
                        <div className="col-6 relDiv">
                          <Form.Group>
                            <label for="emailOTP" className="mandatory">
                              OTP
                            </label>
                            <NumberFormat
                              id="emailOTP"
                              format="######"
                              type="text"
                              onChange={clearErr}
                              autoComplete="off"
                            />
                            <p id="timerOTP" />
                          </Form.Group>
                        </div>
                        <div className="col-6">
                          <button
                            id="verifyStatus"
                            type="button"
                            className="btn btn-sm btn-outline-primary"
                            onClick={verifyOTP}
                          >
                            Verify
                          </button>
                          <button
                            id="resendOTP"
                            type="button"
                            className="btn btn-sm btn-link"
                            onClick={resendOTPMethod}
                          >
                            Resend OTP
                          </button>
                          <button
                            id="reset"
                            type="button"
                            className="btn btn-sm btn-link"
                            onClick={reset}
                          >
                            Reset
                          </button>
                        </div>
                        <p className="errMsg mb-0" />
                        <p id="otpSentMsg" className="mb-0">
                          A 6-digit OTP is sent to this email.
                        </p>
                      </div>
                    </div>
                    <p className="errMsg" />
                  </Form.Group>
                </div>
              </div>
            )}

            <ParentComponentLoc addChildLoc={this.onAddChildLoc}>
              {Object.values(childrenLoc)}
            </ParentComponentLoc>
            {/* </div>} */}
          </form>
          <div className="row">
            <div className="col-12 text-center">
              <button
                type="button"
                onClick={() =>
                  submitCompPro(selDateEff, selDateExp, selDateBus)
                }
                id="profileSaveButton"
                className="btnGen btnSubmits"
              >
                CONTINUE
              </button>
              <p className="errMsg" />
            </div>
          </div>
        </div>
      </div>
    );
  }
  onAddChildLoc = (data) => {
    const { childrenLoc } = this.state;
    let dupChildrenLoc = childrenLoc;
    let rand = Math.random();
    const keys = Object.keys(dupChildrenLoc);
    const index = parseInt(keys[keys.length - 1]) + 1;
    if (!data.location_details) {
      data.location_details = {};
    }
    dupChildrenLoc[index] = (
      <CompLoc
        data={data.location_details}
        key={rand}
        number={index}
        rmvChild={this.onrmvChild}
      />
    );
    this.setState({
      childrenLoc: dupChildrenLoc,
    });
  };
  onrmvChild = (addressId) => {
    const { childrenLoc } = this.state;
    let dupChildrenLoc = childrenLoc;
    delete dupChildrenLoc[addressId];
    this.setState({
      childrenLoc: dupChildrenLoc,
    });
  };
}
async function add() { }
const ParentComponentLoc = (props) => (
  <div className="calculator">
    <div id="allLocations">
      <span className="formTitle">
        <h5 className="company-locations">{"Company Locations"}</h5>
      </span>
      <div id="addressDiv">{props.children}</div>
    </div>
    <div>
      <button
        type="button"
        className="btnInvisible linkLike addLoc transpButton"
        onClick={props.addChildLoc}
      >
        <img src={require("../../../images/plus4.png")} />
      </button>
    </div>
  </div>
);

const CompLoc = (props) => (
  <div id={"officeLoc" + props.number}>
    <div className="row grey">
      {/* <div className="col-sm-1">
        <div className="innerTableHead">{"Location "}</div>
      </div> */}
      <div className="col-sm-3" id="addLine1">
        <Form.Group>
          <label
            for={"addLine1-" + props.number}
            title="Addess Line 1"
            className="mandatory"
          >
            Address Line 1
          </label>
          <input
            name="addLine1"
            id={"addLine1-" + props.number}
            type="text"
            defaultValue={props.data ? props.data.address : ""}
            placeholder="Address Line 1"
            onChange={clearErr}
            autoComplete="off"
          />
          <p className="errMsg addressErrs" />
        </Form.Group>
      </div>
      <div className="col-sm-3" id="addLine2">
        <Form.Group>
          <label for={"addLine2-" + props.number} title="Addess Line 2">
            Address Line 2
          </label>
          <input
            id={"addLine2-" + props.number}
            type="text"
            name="addLine2"
            placeholder="Address Line 2"
            autoComplete="off"
          />
        </Form.Group>
      </div>
      <div className="col-sm-2" id="zip">
        <Form.Group>
          <label
            for={"zipCode-" + props.number}
            className="mandatory"
            title="ZIP Code"
          >
            ZIP Code
          </label>
          <NumberFormat
            name="zipCode"
            id={"zipCode" + props.number}
            format="#####"
            type="text"
            onChange={clearErr}
            value={props.data ? props.data.pincode : ""}
            // onClick={props.data ? setStateAndCity() : ""}
            onBlur={setStateAndCity}
            placeholder="ZipCode"
            autoComplete="off"
          />
          <p className="errMsg addressErrs" />
        </Form.Group>
      </div>
      <div className="col-xs-2" id="state">
        <Form.Group>
          <label className="mandatory" title="State">
            State
          </label>
          <StateList stateToSelect={props.data ? props.data.state : ""} />
          <p className="errMsg addressErrs" />
        </Form.Group>
      </div>
      <div className="col-sm-2">
        <Form.Group>
          <label
            for={"cityName-" + props.number}
            className="mandatory"
            title="City"
          >
            City
          </label>
          <input
            id={"cityName-" + props.number}
            name="cityName"
            type="text"
            onChange={clearErr}
            placeholder="City Name"
            autoComplete="off"
          />
          <p className="errMsg addressErrs" />
        </Form.Group>
      </div>
      <div className="col-xs-1 text-center address-delete">
        <button
          type="button"
          onClick={(event) => {
            props.rmvChild(props.number);
          }}
          className="rmvLoc btnInvisible transpButton"
        >
          <img
            onClick={(event) => deleteAddress(props.number)}
            src={require("../../../images/delete4.png")}
            alt=""
          />
        </button>
      </div>
    </div>
  </div>
);
export default FormCompPro;
