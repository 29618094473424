import React, { Component } from "react";
import utosia from "../../images/homepage//casestudies/utosia@2x.png";
import styles from "./Testimonials.module.css";
import leftarrow from "../../images/homepage//left-arrow.svg";
import rightarrow from "../../images/homepage//right-arrow.svg";
import pageStyles from "../pages/NewHome.module.css";
export default class Testimonials extends Component {
  state = {
    testimonial: [
      [
        `"InsureComp (IC)” is the future of SMBs buying their insurance &/or PEO needs directly online. Affinity Groups take notice, this is the platform of tomorrow.
        I have known the owners of IC for over twenty years and their passion, intellectual capital, and work ethic are unsurpassed. They have developed cutting edge technology for not only Workers Comp, but for co employment as well.`,
        "CEO & Founder",
        "Michael A.Ortoll",
        "iPEO Solutions LLC",
      ],
      [
        `InsureComp has been an impactful platform for our company.  Their underwriting technology and marketing platform create efficiencies that can not only create opportunities but manage them in a streamlined and effective manner.   Raj and his team are incredibly accessible and jump at the opportunity to serve. Most importantly they understand the insurance space which is paramount for rapid results.`,
        "Director",
        "Ralph",
        "Work Comp Now Inc",
      ],
    ],
    current: 0,
  };
  handleleftarrow = () => {
    if (this.state.current >= 1) {
      this.setState({ current: this.state.current - 1 });
    }
  };
  handlerightarrow = () => {
    if (this.state.current < this.state.testimonial.length - 1) {
      this.setState({ current: this.state.current + 1 });
    }
  };
  render() {
    return (
      <div className={"mt-5 " + pageStyles.sectionContainer}>
        <div>
          <h2>Testimonials</h2>
        </div>
        <div className="testimonial">
          <p className={"text-center mx-auto my-5 " + styles.testimonialtext}>
            "{this.state.testimonial[this.state.current][0]}"
          </p>

          <div
            className={
              "d-flex flex-column justify-content-center align-items-center " +
              styles.profilecontainer
            }
          >
            <p className={"m-0 mt-3 " + styles.details}>
              {this.state.testimonial[this.state.current][2]}
            </p>
            <p className={"m-0 " + styles.details}>
              {this.state.testimonial[this.state.current][1]}
            </p>
            <p className={"m-0 " + styles.details}>
              {this.state.testimonial[this.state.current][3]}
            </p>
          </div>
          <div className="text-center my-5">
            <img
              className={styles.arrow}
              src={leftarrow}
              onClick={this.handleleftarrow}
            ></img>
            <span className="mx-3">
              {this.state.current + 1}/{this.state.testimonial.length}
            </span>
            <img
              className={styles.arrow}
              src={rightarrow}
              onClick={this.handlerightarrow}
            ></img>
          </div>
        </div>
      </div>
    );
  }
}
