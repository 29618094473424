import React, { Component } from "react";
import moment from "moment";
import Allques from "../../subcompo/sections-quote/Allques";
import {
  updateQuesList,
  updateAddQuesList,
  appendAddQuesList,
  updateEmodRates,
} from "../../../utils/form_ques";
import { setUWQues } from "../../../utils/session_data";
import { quesList } from "../../../utils/ques_list";
import { quesListAdd } from "../../../utils/ques_list";
import { setAddUWQues } from "../../../utils/session_data";
import Addques from "./Addques";
import { submitQues } from "../../../utils/form_ques";
import $ from "jquery";
import NewProgress from "../../../newcomponent/common/NewProgress";

class FormUnderQues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quesList: quesList,
      quesListAdd: quesListAdd,
      displayMoreClicked: [],
      emodError: [],
      displayError: { status: false, message: "" },
    };
    this.setUWQues = setUWQues.bind(this);
    this.updateQuesList = updateQuesList.bind(this);
    this.setAddUWQues = setAddUWQues.bind(this);
    this.updateAddQuesList = updateAddQuesList.bind(this);
    this.appendAddQuesList = appendAddQuesList.bind(this);
    this.updateEmodRates = updateEmodRates.bind(this);
    this.submitQues = submitQues.bind(this);
  }
  componentDidMount() {
    $("html, body").animate({ scrollTop: 0 }, 250);
    for (let i = 3; i < quesListAdd.length; i++) {
      quesListAdd[i].rates.date1 = quesListAdd[i].rates && quesListAdd[i].rates.date1
        ? quesListAdd[i].rates.date1
        : moment().format("L");
      quesListAdd[i].rates.date2 = quesListAdd[i].rates && quesListAdd[i].rates.date2
        ? quesListAdd[i].rates.date2
        : moment().format("L");
    }
    this.setState({ quesListAdd });
  }

  handleDisplayMoreClicked = (index, state) => {
    let { displayMoreClicked } = this.state;
    displayMoreClicked[index] = state;
    this.setState({ displayMoreClicked });
  };

  updateEmodError = (index, key, state) => {
    let { emodError } = this.state;
    if (!emodError[index]) {
      emodError[index] = {
        date: false,
        rate: false,
      };
    }
    emodError[index][key] = state;
    this.setState({ emodError });
  };

  render() {
    const {
      quesList,
      quesListAdd,
      displayMoreClicked,
      emodError,
      displayError,
    } = this.state;
    let compName = "";
    try {
      let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
      compName = currProspect.companyName;
    } catch (err) {
      console.log(err);
    }
    return (
      <div id="undrQues" className="container-fluid per98">
        <NewProgress />
        <div id="underWritingQues">
          <form>
            <div className="row no-gutters heading-row">
              <span className="formTitle title2">
                And finally, answers to some basic questions about your business
              </span>
              <p className="compName">{compName}</p>
            </div>
            {/* <hr /> */}
            <div className="table-responsive">
              <Allques
                quesList={quesList}
                setUWQues={this.setUWQues}
                updateQuesList={this.updateQuesList}
              />
            </div>
            <p className="formTitle additional-questions">
              Additional Questions
            </p>
            <div className="table-responsive">
              <Addques
                quesListAdd={quesListAdd}
                updateAddQuesList={this.updateAddQuesList}
                appendAddQuesList={this.appendAddQuesList}
                setAddUWQues={this.setAddUWQues}
                updateEmodRates={this.updateEmodRates}
                displayMoreClicked={displayMoreClicked}
                handleDisplayMoreClicked={this.handleDisplayMoreClicked}
                updateEmodError={this.updateEmodError}
                emodError={emodError}
              />
            </div>
          </form>
          <div className="row">
            <div className="col-12 text-center mt-5">
              {displayError.status && (
                <div style={{ color: "#dc3545" }} className="mb-2">
                  *{displayError.message}
                </div>
              )}
              <button
                type="button"
                className="btnGen btnSubmits btn-undrques"
                onClick={() =>
                  this.submitQues(quesList, quesListAdd, displayMoreClicked)
                }
              >
                CONTINUE
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FormUnderQues;
