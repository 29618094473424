import React from "react";
import "./styleAssignment.css";
import Navbar from "../../homepage/Navbar";

function AssignmentDeveloper() {
  return (
    <>
      <Navbar />
      <div className="assignment">
        <h1 className="heading">Developer Assignment</h1>
        <p>
          Download{" "}
          <a href="../../../../../sales-data.txt" target="_blank">
            this data set
          </a>{" "}
          containing sales data from an ice cream parlour. Write a program that
          reads this data and reports:
        </p>
        <p>
          <ul>
            <li>Total sales of the store.</li>
            <li>Month wise sales totals.</li>
            <li>Most popular item (most quantity sold) in each month.</li>
            <li>Items generating most revenue in each month.</li>
            <li>
              For the most popular item, find the min, max and average number of
              orders each month.
            </li>
          </ul>
        </p>
        <p>
          Do not load this data into an SQL table or use libraries like Pandas.
          Get these results using basic data structures like list and maps. The
          idea of this assignment is to assess you on your knowledge of
          algorithms and data structures. Use any programming language that you
          are comfortable in. Neatness and legibility of the code counts. Submit
          the solution at :- to:{" "}
          <a href="mailto:vikas@insurepro20.com">vikas@insurepro20.com</a> cc:{" "}
          <a href="mailto:shwethar@insurepro20.com">shwethar@insurepro20.com</a>{" "}
          .
        </p>

        <h3>FAQ</h3>
        <hr />
        <p>What happens after the code is submitted?</p>
        <p>
          If we like your submission, you will undergo a technical interview
          where you will have to make a few modifications to your submission and
          solve coding assigments of similar complexity. We may take 5 to 10
          working days to get back to you after the solution is submitted.
        </p>
        <hr />
        <p>Can I submit the solution in any language?</p>
        <p>Yes, solutions may be submitted in any language of your choice.</p>
        <hr />
        <p>How much time do I have to complete the assignment?</p>
        <p>
          Ideally within 3 days.We understand that you may be busy with work or
          course commitments, so you may take longer. Do keep in mind that we
          may close the hiring round if you delay submissions.
        </p>
        <hr />
        <p>How do you check the source code for originality?</p>
        <p>
          {" "}
          We can figure out non original submissions during the interview stage.
          If the code is not yours, you will not be able to make the kind of
          enhancements you will be asked to make to the code in the interview.
        </p>
        <hr />
      </div>
    </>
  );
}

export default AssignmentDeveloper;
