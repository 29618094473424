import React, { Component } from "react";
import Navbar from "../component/common/Navbar";
import Loader from "../component/common/Loader";
import SideTab from "./common/SideTab";

class NewQuote extends Component {
  render() {
    return (
      <div className="newquote-mainbody container-fluid">
        <Navbar />
        <SideTab />
        <Loader />
      </div>
    );
  }
}

export default NewQuote;
