import React from "react";
import Navbar from "../homepage/Navbar";
import Footer from "../homepage/Footer";
import Getstarted from "../homepage/Getstarted";
import HomeHero from "../homepage/HomeHero";
import Process from "../homepage/Process";
import Serve from "../homepage/Serve";
import Testimonials from "../homepage/Testimonials";
import Video from "../homepage/Video";
import styles from "./NewHome.module.css";
import { ContactFormProvider } from "../homepage/ContactFormContext";

export default function NewHome() {
  return (
    <div className={styles.landingWrapper}>
      <ContactFormProvider>
        <Navbar />
        <HomeHero />
        {/* <Casestudies /> */}
        <Serve />
        <Video />
        <Process />
        <Testimonials />
        <Getstarted />
        <Footer />
      </ContactFormProvider>
    </div>
  );
}
