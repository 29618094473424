import React from "react";
import moment from "moment";
import BindQuote from "../subcompo/sections-quote/BindQuote";
import { Modal, Tabs, Tab } from "react-bootstrap";

class QuoteTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locations: [],
      carriers: [],
      quotes: {},
      quoteDetails: {},
      displayQuotePDFs: false,
      quotePDFs: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    let { idData } = this.state;
    if (!idData || data !== prevProps.data) {
      this.prepCurrProspectsData(data);
      this.prepTableData(data);
      this.setQuoteStatus(data);
    }
  }

  prepCurrProspectsData = (data) => {
    let currProspectData = {};
    let keys = {
      businessTypeId: "bussiness_type_id",
      companyName: "company_name",
      contactPerson: "contact_person",
      phoneNumber: "phone_number",
      description: "description",
      effectiveDate: "effective_date",
      expirationDate: "expiration_date",
      federalEmployeeId: "fein",
      liabilityLimitId: "liability_limitId",
      date: "date",
    };
    for (let carrier in data) {
      for (let location in data[carrier]) {
        for (let key in keys) {
          currProspectData[key] = data[carrier][location][keys[key]];
        }
      }
    }
    this.setState({ currProspectData });
  };

  setQuoteStatus = (data) => {
    let quoteStatusCarrier = {};
    let quoteStatusState = {};
    let status;
    let carrierName;
    let allBoundFlag = true;
    for (let carrier in data) {
      let state;
      carrierName = carrier.split("_").join(" ");
      if (!(carrierName in quoteStatusCarrier)) {
        quoteStatusCarrier[carrierName] = {};
      }
      let isAllowed = true;
      for (let location in data[carrier]) {
        state = data[carrier][location].carrier_location_data.state;
        status = data[carrier][location].quote_status;
        if (!quoteStatusState[state] || quoteStatusState[state] !== "bound") {
          quoteStatusState[state] = status;
        }
        quoteStatusCarrier[carrierName][state] = status;
        if (!["uwportal", "hold", "reject", "bound"].includes(status)) {
          isAllowed = false;
        }
      }
      if (isAllowed) {
        quoteStatusCarrier[carrierName].disableCheckBox = true;
      }
    }
    for (let state in quoteStatusState) {
      if (quoteStatusState[state] !== "bound") {
        allBoundFlag = false;
        break;
      }
    }
    this.setState({ quoteStatusCarrier, quoteStatusState, allBoundFlag });
  };

  prepTableData = (data) => {
    let userName;
    let carrierList = [];
    let stateList = [];
    let quotesData = {};
    let idData = {};
    let carrierName;
    let quoteDetails = {};
    for (let carrier in data) {
      carrierName = carrier.split("_").join(" ");
      if (!(carrierName in quotesData)) {
        quotesData[carrierName] = {};
      }
      if (!(carrierName in idData)) {
        idData[carrierName] = {};
      }
      carrierList.push(carrierName);
      for (let location in data[carrier]) {
        quoteDetails.companyName = data[carrier][location].company_name;
        quoteDetails.fein = data[carrier][location].fein;
        quoteDetails.effectiveDate = data[carrier][location].effective_date;
        quoteDetails.expirationDate = data[carrier][location].expiration_date;

        if (!userName) {
          userName = data[carrier][location].user_email_id;
          this.setState({ userName });
        }
        let state = data[carrier][location].carrier_location_data.state;
        let tmp =
          data[carrier][location].carrier_location_data
            .total_estimated_annual_premium;
        if (!(state in quotesData[carrierName])) {
          quotesData[carrierName][state] = 0;
        }
        if (!(state in idData[carrierName])) {
          idData[carrierName][state] = data[carrier][location].uuid_carrier;
        } else {
          idData[carrierName][
            state
          ] += `!!${data[carrier][location].uuid_carrier}`;
        }

        quotesData[carrierName][state] += tmp;
        if (!stateList.includes(state)) {
          stateList.push(state);
        }
      }
    }
    this.setState(
      {
        carriers: carrierList,
        locations: stateList,
        quotes: quotesData,
        idData,
        quoteDetails,
      },
      () => {
        this.createQuoteTable(carrierList, stateList, quotesData);
      }
    );
  };

  createQuoteTable = (carriers, locations, quotes) => {
    let quoteTable = {};
    for (let i = 0; i < locations.length; i++) {
      let location = locations[i];
      let locationQuotes = [];
      for (let j = 0; j < carriers.length; j++) {
        let carrier = carriers[j];
        if (quotes[carrier][location]) {
          locationQuotes.push(quotes[carrier][location]);
        } else {
          locationQuotes.push(Infinity);
        }
      }
      let lowest_quote = Math.min(...locationQuotes);
      for (let j = 0; j < carriers.length; j++) {
        let carrier = carriers[j];
        quoteTable[carrier] || (quoteTable[carrier] = { selected: false });
        quoteTable[carrier][location] = {
          quoted: locationQuotes[j] !== Infinity,
          quote: Math.round(locationQuotes[j]),
          diff: {
            value: locationQuotes[j] - lowest_quote,
            percent: Math.round(
              ((locationQuotes[j] - lowest_quote) * 100) / lowest_quote
            ),
          },
          selected: false,
        };
      }
    }
    this.setState({ quoteTable });
  };

  handleCarrierChecked = (event) => {
    let { quoteTable, idData, quoteStatusCarrier } = this.state;
    let quoteTableCopy = JSON.parse(JSON.stringify(quoteTable));
    let carrier = event.target.name;
    let checked = event.target.checked;

    let carriers = Object.keys(quoteTableCopy);
    if (checked) {
      for (let i = 0; i < carriers.length; i++) {
        let selectedVal = false;
        if (carrier === carriers[i] && !quoteTableCopy[carrier].selected) {
          selectedVal = true;
        }
        let states = Object.keys(quoteTableCopy[carriers[i]]);
        for (let j = 0; j < states.length; j++) {
          if (
            states[j] === "selected" ||
            !quoteTableCopy[carriers[i]][states[j]].quoted
          ) {
            continue;
          }
          let status = ["uwportal", "hold", "reject"];
          let isSelected = selectedVal;

          if (
            selectedVal &&
            quoteStatusCarrier[carrier[i]] &&
            quoteStatusCarrier[carrier[i]][states[j]] &&
            quoteStatusCarrier[carrier[i]] &&
            status.includes(quoteStatusCarrier[carrier[i]][states[j]])
          ) {
            isSelected = false;
          }
          quoteTableCopy[carriers[i]][states[j]].selected = isSelected;
          if (
            isSelected &&
            !(
              quoteStatusCarrier[carrier[i]] &&
              quoteStatusCarrier[carrier[i]][states[j]] &&
              quoteStatusCarrier[carrier[i]][states[j]] === "bound"
            )
          ) {
            quoteTableCopy[carriers[i]][states[j]].id =
              idData[carriers[i]][states[j]];
          }
          if (
            isSelected &&
            quoteStatusCarrier[carrier[i]] &&
            quoteStatusCarrier[carrier[i]][states[j]] &&
            quoteStatusCarrier[carrier[i]][states[j]] === "bound"
          ) {
            delete quoteTableCopy[carriers[i]][states[j]].id;
          }
        }
        quoteTableCopy[carriers[i]].selected = selectedVal;
      }
    } else {
      let states = Object.keys(quoteTableCopy[carrier]);
      for (let j = 0; j < states.length; j++) {
        if (
          states[j] === "selected" ||
          !quoteTableCopy[carrier][states[j]].quoted
        ) {
          continue;
        }
        quoteTableCopy[carrier][states[j]].selected = checked;
      }
      quoteTableCopy[carrier].selected = checked;
    }
    this.setState({ quoteTable: quoteTableCopy });
  };

  handleQuoteChecked = (event) => {
    const { currProspectData } = this.state;
    let carrierName;
    let stateName;
    const id = event.target.id;
    [carrierName, stateName] = event.target.name.split(":");
    let quoteTableCopy = JSON.parse(JSON.stringify(this.state.quoteTable));
    // Validations
    // If we are selecting one carrier for a given state, deselect all
    // other carriers for that state.
    let selected = !quoteTableCopy[carrierName][stateName].selected;
    if (selected) {
      let carriers = Object.keys(quoteTableCopy);
      for (let i = 0; i < carriers.length; i++) {
        quoteTableCopy[carriers[i]][stateName] &&
          (quoteTableCopy[carriers[i]][stateName].selected = false);
      }
    }
    quoteTableCopy[carrierName][stateName].selected = !quoteTableCopy[
      carrierName
    ][stateName].selected;
    quoteTableCopy[carrierName][stateName].id = id;

    let addList = [];
    let addKeys = [
      "streetLineOne",
      "streetLineTwo",
      "city",
      "stateCode",
      "zipCode",
    ];
    for (let carrier in quoteTableCopy) {
      let selectedStateCount = 0;
      let countOfQuotes = 0;
      for (let state in quoteTableCopy[carrier]) {
        if (
          quoteTableCopy[carrier][state] &&
          quoteTableCopy[carrier][state].quoted
        ) {
          countOfQuotes += 1;
        }
        if (quoteTableCopy[carrier][state].selected) {
          selectedStateCount += 1;
          let x = quoteTableCopy[carrier][state].id;
          let obj = {};
          x.split("!!").map((val, index1) => {
            obj = {};
            val
              .split("+")
              .reverse()[0]
              .split("_")
              .map((add, index2) => {
                obj[addKeys[index2]] = add;
              });
            obj.addressId = index1;
            addList.push(obj);
          });
        }
      }
      if (countOfQuotes === selectedStateCount) {
        quoteTableCopy[carrier].selected = true;
      } else {
        quoteTableCopy[carrier].selected = false;
      }
    }
    currProspectData.prospectAddresses = addList;
    this.setState({ quoteTable: quoteTableCopy, currProspectData });
  };

  isDisabled() {
    let { quoteTable, quoteStatusCarrier } = this.state;
    let flag = false;
    for (let carrier in quoteTable) {
      for (let state in quoteTable[carrier]) {
        if (
          quoteStatusCarrier[carrier][state] !== "bound" &&
          quoteTable[carrier][state].selected === true
        ) {
          flag = true;
          break;
        }
      }
      if (flag) {
        break;
      }
    }
    return !flag;
  }

  //sorting the quote-table based on best deals and uwportal criteria
  sortByBestDeals = (obj, obj2) => {
    let newObj = {};
    let bestDealsCount = 0;
    let { locations } = this.state;
    let { carriers } = this.state;
    for (const c of carriers) {
      for (const l of locations) {
        if (obj[c][l].diff.value === 0) {
          bestDealsCount = bestDealsCount + 1;
        }
        if (obj2[c][l] === "uwportal" || obj2[c][l] === "hold") {
          bestDealsCount = bestDealsCount - 1;
        }
      }
      newObj[c] = bestDealsCount;
      bestDealsCount = 0;
    }
    let keys = Object.keys(newObj);
    let values = Object.values(newObj);
    values = values.sort().reverse();
    let sortedCarriers = [];
    for (let v of values) {
      for (let k of keys) {
        if (newObj[k] === v) {
          sortedCarriers.push(k);
          keys = keys.filter((e) => e !== k);
        }
      }
    }
    return sortedCarriers;
  };

  onQuoteReady = (quotes) => {
    console.log("Quote is ready to display");
    console.log(quotes);
    this.setState({ displayQuotePDFs: true, quotePDFs: quotes });
  };

  render() {
    const {
      quoteTable,
      idData,
      quoteStatusCarrier,
      quoteStatusState,
      allBoundFlag,
      quoteDetails,
      userName,
      currProspectData,
    } = this.state;

    let isHistoryVariant = this.props.variant === "history";
    let sortedCarriers = [];
    if (quoteTable && quoteStatusCarrier) {
      sortedCarriers = this.sortByBestDeals(quoteTable, quoteStatusCarrier);
    }

    let effectiveDate, expirationDate, fein;

    if (Object.entries(quoteDetails).length !== 0) {
      effectiveDate = moment(quoteDetails.effectiveDate).format("M-D-YYYY");
      expirationDate = moment(quoteDetails.expirationDate).format("M-D-YYYY");
      fein = String(quoteDetails.fein).split("");
      fein.splice(2, 0, "-");
      fein = fein.join("");
    }
    let viewQuoteDisable = this.isDisabled();
    return (
      <div className="quote-table">
        {/* <p /> */}
        <div className="row no-gutters head-row hr1">
          {isHistoryVariant || <h3>Boom! Here are your options!</h3>}
          <h5 className="quote-table-company-name">
            {quoteDetails.companyName ? quoteDetails.companyName + "\t" : ""}
          </h5>
        </div>
        <div className="row no-gutters head-row hr2">
          <p>
            <span className="hr2-lite">FEIN</span> : {fein ? fein + "\t" : ""}
          </p>
          <p>
            <span className="hr2-lite">Effective Date</span> :{" "}
            {effectiveDate ? effectiveDate + "\t\t\t" : ""}
          </p>
          <p>
            <span className="hr2-lite">Expiration Date</span> :{" "}
            {expirationDate ? expirationDate + "\t" : ""}
          </p>
        </div>
        <div style={{ fontSize: "18px" }} className="hr3">
          Please select your preferred option/(s) and continue
        </div>
        {/* <div className="hr4">To view quotes, Select checkboxes and view</div> */}
        <div className="row no-gutters body-row">
          <div id="table-container" style={{ overflow: "auto", padding: "5px" }}>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th>Carriers</th>
                  {this.state.locations.map((l) => (
                    <th>
                      <span style={{ textTransform: "uppercase" }}>{l}</span>
                      {quoteStatusState[l] === "bound" && (
                        <span className="bound"> Bound</span>
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {quoteTable &&
                  sortedCarriers.map((c) => (
                    <tr>
                      <th style={{ textTransform: "capitalize", minWidth: "8rem" }}>
                        <div className="d-flex flex-row align-items-center">
                          <input
                            id={`carrierName-${c}`}
                            disabled={
                              quoteStatusCarrier[c].disableCheckBox ||
                                allBoundFlag
                                ? true
                                : false
                            }
                            type="checkbox"
                            className="mr-1"
                            checked={
                              quoteTable[c] ? quoteTable[c].selected : false
                            }
                            name={c}
                            onChange={this.handleCarrierChecked}
                          />{" "}
                          <label for={`carrierName-${c}`}>{c}</label>
                        </div>
                      </th>
                      {this.state.locations.map((l) => (
                        <td>
                          {quoteTable[c][l] && quoteTable[c][l].quoted ? (
                            <div className="d-flex flex-row align-items-center">
                              <input
                                disabled={
                                  quoteStatusCarrier[c][l] === "uwportal" ||
                                    quoteStatusState[l] === "bound" ||
                                    quoteStatusCarrier[c][l] === "hold" ||
                                    quoteStatusCarrier[c][l] === "reject"
                                    ? "disabled"
                                    : false
                                }
                                id={idData[c][l]}
                                type="checkbox"
                                className="check"
                                checked={
                                  quoteStatusCarrier[c][l] === "bound" ||
                                  quoteTable[c][l].selected
                                }
                                name={c + ":" + l}
                                onChange={this.handleQuoteChecked}
                              />
                              <label for={idData[c][l]}>
                                ${quoteTable[c][l].quote.toLocaleString("en")}
                              </label>
                              <span className="d-flex flex-column">
                                {quoteTable[c][l].diff.value ? (
                                  <span className="higher">
                                    {quoteTable[c][l].diff.percent + "% higher"}
                                  </span>
                                ) : (
                                  <span className="best">Best Deal</span>
                                )}
                                {quoteStatusCarrier &&
                                  (quoteStatusCarrier[c][l] === "uwportal" ||
                                    quoteStatusCarrier[c][l] === "hold") && (
                                    <span className="uw text-danger">
                                      UW Referral
                                    </span>
                                  )}
                                {quoteStatusCarrier &&
                                  quoteStatusCarrier[c][l] === "reject" && (
                                    <span className="uw text-danger">
                                      Rejected
                                    </span>
                                  )}
                              </span>
                            </div>
                          ) : (
                            <p> - </p>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {quoteTable && !allBoundFlag && (
            <BindQuote
              quoteTable={quoteTable}
              userName={userName}
              currProspectData={currProspectData}
              quoteStatusCarrier={quoteStatusCarrier}
              viewQuoteDisable={viewQuoteDisable}
              isHistoryVariant={isHistoryVariant}
              onQuoteReady={this.onQuoteReady}
            />
          )}
        </div>
        <Modal
          show={this.state.displayQuotePDFs}
          onHide={() => this.setState({ displayQuotePDFs: false })}
          dialogClassName="modal-full"
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Quotes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs defaultActiveKey="quote-0" id="uncontrolled-tab-example">
              {this.state.quotePDFs.map((quote, i) => (
                <Tab
                  key={"quote-" + i}
                  eventKey={"quote-" + i}
                  title={quote.carrier}
                >
                  <iframe
                    src={quote.fileURL}
                    style={{ width: "100%", height: "100%" }}
                  />
                </Tab>
              ))}
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default QuoteTable;
