import React, { useEffect, useState } from "react";
import axios from "axios";
import "./DashBoardWithMap.scss";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { awsUrl } from "../../config";
import JSZip, { file } from "jszip";
import { saveAs } from "file-saver";
import Swal from "sweetalert2";
import { BeatLoader } from "react-spinners";
import toast, { Toaster } from "react-hot-toast";

const AutomationTesting = () => {
  let data = [];
  const [s3FileList, setS3FileList] = useState([]);
  const [selectBucket, setSelectBucket] = useState(" ");
  const [downloadFileName, setDownloadFileName] = useState(" ");
  const [isLoading, setIsLoading] = useState(false);

  // Fetching the List from s3 bucket server
  const bucketListHandler = () => {
    axios
      .get(awsUrl + "/api/getAutomationBucketList")
      .then(async (res) => {
        console.log(res.data);
        let BucketlistRecieved = res.data;
        for (let values of BucketlistRecieved) {
          data.push({
            label: values["Key"],
            value: values["Key"],
          });
        }
        setS3FileList(data);
      })
      .catch((err) => {
        console.log(err, " unable to call the API");
      });
  };

  useEffect(() => {
    bucketListHandler();
  }, []);

  const toastSuccess = (text) =>
    toast.success(text, {
      duration: 3000,
    });

  // Function to Multiple select and deselect and send for Test
  function onChangeDownloadDropdown(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
    let files = [];
    for (let elements of value) {
      if (elements.value === "*") {
        for (let file of s3FileList) {
          files.push(file.value);
        }
      } else {
        files.push(elements.value);
      }
    }
    let combinefilename = String(files);
    setDownloadFileName(combinefilename);
  }

  //Function to convert URL to Zip
  const DownloadZipFile = async (urls) => {
    const zip = new JSZip();
    const promises = urls.map(async (url) => {
      return fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const filename = url.split("/")[3].split("?")[0];
          zip.file(filename, blob);
        });
    });

    return Promise.all(promises).then(() => {
      setIsLoading(false);
      return zip.generateAsync({ type: "blob" });
    });
  };

  // FUNCTION FOR DOWNLOADING FILES
  const downloadFiles = async (files) => {
    axios
      .get(awsUrl + `/api/getAutomationFilesDownload/${files}`)
      .then((response) => {
        let ResultFileURL = response.data;
        console.log(ResultFileURL);
        DownloadZipFile(ResultFileURL)
          .then((blob) => {
            saveAs(blob, "Result_Files.zip");
            Swal.fire("File SuccessFully Downloaded", "", "success");
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              icon: "error",
              title: "Downloaded Failed",
              text: "Unable to Compress the File In Zip",
            });
          });
      })
      .catch((err) => {
        console.log(err, "Unable to call the Testing API");
        Swal.fire({
          icon: "error",
          title: "Downloaded Failed",
          text: "Unable to call the Download API",
        });
        setIsLoading(false);
      });
  };

  //Function to Uplaod in S3 Bucket
  const uploadFile = async (event) => {
    event.preventDefault();
    let uploadFileData = event.target.uploadFiles.files[0];
    console.log(uploadFileData, "File Uploaded");
    let filename = uploadFileData.name;
    let filetype = uploadFileData.type;
    let datatoSend = { filename: filename, filetype: filetype };
    let fileExtension = uploadFileData.name.split(".").reverse()[0];
    if (fileExtension === "xlsx") {
      const signedUrlResponse = await axios.post(
        awsUrl + "/api/getAutomationFilesUpload",
        datatoSend
      );
      console.log(signedUrlResponse);

      let options = { header: { "Content-Type": filetype } };
      await axios
        .put(signedUrlResponse.data, uploadFileData, options)
        .then((res) => {
          console.log("File Uploaded SuccessFully");
          bucketListHandler();
          Swal.fire("File Uploaded in Bucket!", "", "success");
        })
        .catch((errr) => {
          console.log("ERROR:", errr);
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Uploading File Failed",
        text: "Upload Valid XLSX file",
      });
    }
  };

  //Function to test the uploaded files
  const startFilesTesting = (filesNameselected) => {
    setIsLoading(true);
    console.log(filesNameselected, ":filesNameselected");
    let filesBody = { files: filesNameselected };
    axios
      .post(awsUrl + "/api/verifyQuotes", JSON.stringify(filesBody))
      .then(async (res) => {
        if (res.data) {
          let responseArray = res.data;
          console.log(res.data, "Testing Done Object");
          let errorCheckArray = [];
          for (let index = 0; index < responseArray.length; index++) {
            errorCheckArray.push(responseArray[index].status);
          }
          let errorCheck = true;
          if (errorCheckArray.includes("exception occurred")) {
            errorCheck = false;
          }
          if (errorCheck) {
            toastSuccess("Testing complete! Downloading results");
            downloadFiles(filesNameselected);
          } else {
            Swal.fire({
              icon: "error",
              title: "Testing Failed",
              text: "Please Make Sure All the Coloumn in Excel is filled",
            });
            setIsLoading(false);
          }
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Testing Failed",
          text: "Please Make Sure All the Coloumn in Excel is filled",
        });
        setIsLoading(false);
        console.log(err, " unable to call the API");
      });
  };

  return (
    <>
      <div className="tab-content tab-content-automation">
        <div id="AutomationPortal" className="mx-auto">
          <h3 className="text-center mt-5">Automation Testing</h3>
          <div className="card my-5 mx-auto">
            <div className="card-body">
              <div className="upload-section">
                <form
                  onSubmit={uploadFile}
                  className="d-flex justify-content-between align-items-end"
                >
                  <div class="d-flex flex-column">
                    <b>
                      <label for="uploadFile" className="m-0">
                        Select file to upload
                      </label>
                    </b>
                    <input
                      className="p-0"
                      type="file"
                      name="uploadFiles"
                      id="uploadFile"
                    />
                  </div>
                  <button class="btn btn-outline-info" type="submit">
                    Upload
                  </button>
                </form>
              </div>
              <hr />
              <div className="test-section">
                <div className="d-flex justify-content-between align-items-end">
                  <div className="test-input-section d-flex flex-column">
                    <b>Select files to test</b>
                    {s3FileList && (
                      <ReactMultiSelectCheckboxes
                        options={[{ label: "All", value: "*" }, ...s3FileList]}
                        className="downloadFiles"
                        placeholderButtonLabel="Select files for testing"
                        value={selectBucket}
                        onChange={onChangeDownloadDropdown}
                        setState={setSelectBucket}
                      />
                    )}
                  </div>
                  <button
                    class="btn btn-outline-primary"
                    onClick={() => {
                      startFilesTesting(downloadFileName);
                    }}
                  >
                    Run Tests
                  </button>
                </div>
              </div>
              <div>
                {isLoading && (
                  <BeatLoader
                    style={{
                      marginLeft: "5%",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </>
  );
};

export default AutomationTesting;
