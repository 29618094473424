import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <div id="footerMain" className="container-fluid">
        <div className="row row-1">
          <div className="col-md-6 col-img">
            <Link to={"/"}>
              <img src={require("../../images/insurecomp_mainlogo_var2.png")} />
            </Link>
          </div>
          <div className="col col-auto">
            <ul>
              <Link to={"/how-it-works/brochure"}>
                <li>About Us</li>
              </Link>
            </ul>
          </div>
          <div className="col col-auto">
            <ul>
              <li className="section-title">Resources</li>
              <li>
                <a
                  href="https://www.ncci.com/pages/default.aspx"
                  target="_blank"
                >
                  NCCI
                </a>
              </li>
              <Link to={"/faq"}>
                <li>FAQ</li>
              </Link>
            </ul>
          </div>
        </div>
        <div className="row t-and-c-row">
          <div className="col-sm-2 dull">
            <Link to={"/terms"}>Terms &amp; Conditions</Link>
          </div>
          <div className="col-sm-5 dull">&copy;2019 All Rights Reserved</div>
          <div className="col-sm-2 ml-auto text-right">
            <a href="#" className="back-to-top">
              Back to Top &#9650;
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
