//component for description code mapping
import React, { Component } from "react";
import { awsUrl2 } from "../../config";
import { getHeader } from "../../utils/common";
import AsyncSelect from "react-select/async";
import { Form } from "react-bootstrap";

const axios = require("axios");
class DescriptionCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descList: "",
      codeList: false,
      ccList: "",
      selectedOption: null,
      inputValue: null,
      noOptionsMessage: "Enter 3 characters to start",
    };
  }

  componentDidUpdate = () => {
    if (this.props.locInfoParent !== this.state.locInfo) {
      this.setState({ locInfo: this.props.locInfoParent });
    }
    if (this.props.locInfoErrorStoreParent !== this.state.locInfoErrorStore) {
      this.setState({ locInfoErrorStore: this.props.locInfoErrorStoreParent });
    }
  };

  handleSelectChange = (selectedOption) => {
    const {
      addressBlockNo,
      addressChildNo,
      handleMinMax,
      updateLocInfo,
      updateLocInfoErrorStore,
    } = this.props;
    const { locInfo } = this.state;
    let ft, pt, payroll;

    if (locInfo && locInfo[addressBlockNo][addressChildNo]) {
      ft = locInfo[addressBlockNo][addressChildNo].ft;
      pt = locInfo[addressBlockNo][addressChildNo].pt;
      payroll = locInfo[addressBlockNo][addressChildNo].payroll;
    }

    this.setState({ inputValue: selectedOption });
    updateLocInfo(
      addressBlockNo,
      addressChildNo,
      "classCodeDescription",
      selectedOption.value
    );
    updateLocInfoErrorStore(
      addressBlockNo,
      addressChildNo,
      "classCodeDescription",
      false
    );
    handleMinMax(addressBlockNo, addressChildNo);
    this.setState({ prevDescription: selectedOption.value });
  };

  loadOptions = async (value, callback) => {
    let minLength = isNaN(value) ? 3 : 1;
    if (value.length >= minLength) {
      let returnList = [];
      const { state } = this.props;
      // const state = "fl";
      const header = await getHeader();
      await axios
        .get(awsUrl2 + `/api/getCcodes/${state}/${value}`, header)
        .then((res) => {
          let dbObjsList = res.data.data;
          if (dbObjsList.length === 0) {
            this.setState({ noOptionsMessage: "No data found" });
          }
          dbObjsList.forEach((element) => {
            returnList.push({
              value: `${element.class_code}: ${element.description}`,
              label: `${element.class_code}: ${element.description.trim()}`,
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
      return callback(returnList);
    } else {
      this.setState({ noOptionsMessage: "Enter 3 characters to start" });
    }
  };

  customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "35px",
      border: "1px solid #ababab",
      background: "transparent",
      borderRadius: "4px",
    }),
    input: (provided) => ({
      ...provided,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#2b2d31",
      fontWeight: "normal",
    }),
  };

  customStylesErr = {
    control: (provided) => ({
      ...provided,
      minHeight: "35px",
      border: "1px solid red",
      background: "transparent",
      borderRadius: "4px",
    }),
    input: (provided) => ({
      ...provided,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#2b2d31",
      fontWeight: "normal",
    }),
  };

  render() {
    const {
      noOptionsMessage,
      inputValue,
      locInfoErrorStore,
      prevDescription,
    } = this.state;
    const { addressBlockNo, addressChildNo, classCodeDescription } = this.props;
    let descValue;
    if (inputValue) {
      descValue = inputValue;
    } else if (
      !inputValue &&
      (classCodeDescription || classCodeDescription === "")
    ) {
      descValue = [
        {
          label: classCodeDescription,
          value: classCodeDescription,
        },
      ];
    }

    return (
      <div
        className="col-sm-3"
        id={`class-code-desc-${addressBlockNo}${addressChildNo}`}
      >
        <Form.Group>
          <label className="mandatory" title="Worker's compensation code">
            Code
          </label>
          <div
            title={`${
              classCodeDescription
                ? classCodeDescription
                : "Enter 3 characters to start"
            }`}
          >
            <AsyncSelect
              value={descValue}
              className="class-desc-select"
              classNamePrefix="class-desc-select"
              cacheOptions
              placeholder="Enter 3 chars"
              defaultOptions={
                classCodeDescription
                  ? [
                      {
                        value: classCodeDescription,
                        label: classCodeDescription,
                      },
                    ]
                  : []
              }
              loadOptions={this.loadOptions}
              onFocus={() => {
                const {
                  addressBlockNo,
                  addressChildNo,
                  handleMinMax,
                  updateLocInfo,
                  updateLocInfoErrorStore,
                } = this.props;

                this.setState({
                  prevDescription: classCodeDescription,
                  inputValue: null,
                });

                updateLocInfo(
                  addressBlockNo,
                  addressChildNo,
                  "classCodeDescription",
                  ""
                );
                updateLocInfoErrorStore(
                  addressBlockNo,
                  addressChildNo,
                  "classCodeDescription",
                  false
                );
                handleMinMax(addressBlockNo, addressChildNo);
                console.log("++inside focus");
              }}
              onBlur={() => {
                if (!prevDescription) return;
                let desc = prevDescription.split(":")[1].trim();
                if (desc !== "no description found") {
                  const {
                    addressBlockNo,
                    addressChildNo,
                    handleMinMax,
                    updateLocInfo,
                    updateLocInfoErrorStore,
                  } = this.props;

                  updateLocInfo(
                    addressBlockNo,
                    addressChildNo,
                    "classCodeDescription",
                    prevDescription
                  );
                  updateLocInfoErrorStore(
                    addressBlockNo,
                    addressChildNo,
                    "classCodeDescription",
                    false
                  );
                  handleMinMax(addressBlockNo, addressChildNo);
                  this.setState({
                    inputValue: {
                      label: prevDescription,
                      value: prevDescription,
                    },
                  });
                  console.log("++inside blur");
                }
              }}
              noOptionsMessage={() => noOptionsMessage}
              onChange={this.handleSelectChange}
              styles={
                locInfoErrorStore &&
                locInfoErrorStore[addressBlockNo] &&
                locInfoErrorStore[addressBlockNo][addressChildNo] &&
                locInfoErrorStore[addressBlockNo][addressChildNo]
                  .classCodeDescription
                  ? this.customStylesErr
                  : this.customStyles
              }
              style={{
                borderColor: "red",
              }}
            />
          </div>
        </Form.Group>
      </div>
    );
  }
}

export default DescriptionCode;
