import React from "react";
import Navbar from "../homepage/Navbar";
import Footer from "../homepage/Footer";
import pageStyles from "./NewHome.module.css";
import { ContactFormProvider } from "../homepage/ContactFormContext";

export default function NewHome() {
  return (
    <div className={pageStyles.landingWrapper}>
      <ContactFormProvider>
        <Navbar />
        <div className={pageStyles.sectionContainer}>
          <div className="row">
            <div className="col" style={{ padding: "50px 0px" }}>
              <h1>Blog</h1>
              <p>Coming soon.</p>
            </div>
          </div>
        </div>
        <Footer />
      </ContactFormProvider>
    </div>
  );
}
