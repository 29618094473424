import React, { Component } from "react";
import {
  submitComrRate,
  dropReadFile,
  histClaimCalcTI,
  histClaimCalcTP,
  histClaimCalcTR,
  zeroDefault,
  handleCheck,
  getMinMax,
  removeStateAggregateData,
} from "../../../utils/form_cmprRates";
import FormCompLoc from "./FormCompLoc";
import {
  cookie,
  cookie2,
  setHistoricalClaims,
} from "../../../utils/session_data";
import NumberFormat from "react-number-format";
import StateAggr from "./StateAggr";
import { clearErrHistoricalClaims } from "../../../utils/common";
import $ from "jquery";
import NewProgress from "../../../newcomponent/common/NewProgress";
import Dropzone from "react-dropzone";
import { Form } from "react-bootstrap";

var prospect, loggedin, addressCount;

class FormCompareRate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addressCount: 0,
      showUploadProgress: false,
      uploadError: false,
      loss_data: {},
      historicalClaimsError: false,
      droppedFile: "",
      lossFileMissingError: false,
      historicalClaims: {},
      historicalClaimsCheck: {},
      historicalClaimsErrorStore: {},
      locInfoErrorStore: {},
      submitClicked: false,
      minMax: {},
    };
    this.dropReadFile = dropReadFile.bind(this);
    this.submitComrRate = submitComrRate.bind(this);
    this.setHistoricalClaims = setHistoricalClaims.bind(this);
    this.handleCheck = handleCheck.bind(this);
    this.clearErr = clearErrHistoricalClaims.bind(this);
    this.zeroDefault = zeroDefault.bind(this);
    this.getMinMax = getMinMax.bind(this);
    this.removeStateAggregateData = removeStateAggregateData.bind(this);
  }

  componentWillMount() {
    prospect = cookie();
    addressCount = cookie2();
    loggedin = sessionStorage.getItem("isLoggedIn");
    let locInfo = {};
    if (prospect && addressCount && loggedin) {
      this.setState({
        addressCount: parseInt(addressCount),
      });
    }

    const currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
    for (let j = 0; j < addressCount; j++) {
      if (
        currProspect &&
        currProspect.prospectAddresses &&
        currProspect.prospectAddresses[j].addressId !== undefined
      ) {
        if (currProspect.prospectAddresses[j].classCodes) {
          if (!locInfo[j]) {
            locInfo[j] = {};
          }
          for (let addressChildNo in currProspect.prospectAddresses[j]
            .classCodes) {
            let classCodeDescription = `${currProspect.prospectAddresses[j].classCodes[addressChildNo].code}:${currProspect.prospectAddresses[j].classCodes[addressChildNo].desc}`;
            locInfo[j][addressChildNo] = {
              classCodeDescription: classCodeDescription,
              ft:
                currProspect.prospectAddresses[j].classCodes[addressChildNo].ft,
              pt:
                currProspect.prospectAddresses[j].classCodes[addressChildNo].pt,
              payroll: `$${currProspect.prospectAddresses[j].classCodes[addressChildNo].payroll}`,
            };
          }
        } else {
          locInfo[j] = { 0: {} };
        }
      }
    }

    this.setState({ locInfo }, () => {
      for (let addressBlockNo in locInfo) {
        for (let addressChildNo in locInfo[addressBlockNo]) {
          this.handleMinMax(addressBlockNo, addressChildNo);
        }
      }
    });
  }
  componentDidMount() {
    $("#loader").css("display", "none");
    $("html, body").animate({ scrollTop: 0 }, 250);

    let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));

    if (currProspect) {
      if (currProspect.effectiveDate) {
        let currYear = new Date(currProspect.effectiveDate).getFullYear();
        let historyLimitYear = new Date(
          currProspect.businessStartDate
        ).getFullYear();
        let historicalClaimsCheck = {};
        for (let i = 0; i < 5; i++) {
          if (currYear >= historyLimitYear) {
            historicalClaimsCheck[currYear] = false;
          } else {
            historicalClaimsCheck[currYear] = true;
          }
          currYear--;
        }
        this.setState({ historicalClaimsCheck });
      }
      if (currProspect.historicalClaims) {
        this.setHistoricalClaims();
      }
      if (currProspect.lossFileName) {
        this.setState({ droppedFile: currProspect.lossFileName });
      }
    }
  }

  handleFileUpload = (event) => {
    this.setState({ file: event.target.files });
  };

  autoFillAcordData = (data) => {
    this.setState({ loss_data: data });
  };

  handleChange = (e, key) => {
    let { historicalClaims } = this.state;
    let year = e.target.id.split("-")[1];

    if (!historicalClaims[year]) {
      historicalClaims[year] = {};
    }
    historicalClaims[year][key] = e.target.value;

    if (key === "totInc") {
      historicalClaims = histClaimCalcTI(historicalClaims, year);
    }
    if (key === "totPaid") {
      historicalClaims = histClaimCalcTP(historicalClaims, year);
    }
    if (key === "totRes") {
      historicalClaims = histClaimCalcTR(historicalClaims, year);
    }
    this.setState({ historicalClaims });
  };

  handleTotPaidBlur = (e) => {
    // zeroDefault(e);
    let { historicalClaims } = this.state;
    let year = e.target.id.split("-")[1];
    historicalClaims = histClaimCalcTP(historicalClaims, year);
    this.setState({ historicalClaims });
  };

  createTable = () => {
    let table = [];
    let j = 0;
    let {
      historicalClaims,
      historicalClaimsCheck,
      historicalClaimsErrorStore,
      submitClicked,
    } = this.state;
    let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
    let loss_data = currProspect ? currProspect.historicalClaims : null;
    let loss_data_index;
    let historyLimitYear = 0;
    let backgroundColor = "";
    let absYear;

    let rowId, rowIdDup, rowIdYear, rowIdDupYear;
    if (currProspect && currProspect.effectiveDate) {
      rowIdYear = new Date(currProspect.effectiveDate).getFullYear();
      rowIdDupYear = new Date(currProspect.effectiveDate).getFullYear();
    } else {
      rowIdYear = new Date().getFullYear();
      rowIdDupYear = new Date().getFullYear();
    }
    if (currProspect && currProspect.businessStartDate) {
      historyLimitYear = new Date(currProspect.businessStartDate).getFullYear();
    }

    for (let i = 0; i < 10; i++) {
      rowId = rowIdYear - j;
      rowIdDup = rowIdDupYear - i;
      absYear = !absYear ? rowIdDup : absYear;
      if (historicalClaimsCheck[absYear]) {
        backgroundColor = "#dddddd";
      } else {
        backgroundColor = "";
      }
      rowId = rowId.toString();
      if (i % 2 === 0) {
        j++;
        let yearId = Number(rowId);
        loss_data_index = loss_data
          ? loss_data.findIndex((e) => e.year === rowId)
          : null;
        table.push(
          <tr id={rowIdDup} key={rowIdDup}>
            <td className="per30">
              <Form.Group>
                <label for={`carrier-${absYear}`} className="mandatory">
                  Carrier
                </label>
                <input
                  autoComplete="off"
                  id={`carrier-${absYear}`}
                  type="text"
                  name="carText"
                  onChange={(e) => this.handleChange(e, "carrier")}
                  style={{
                    backgroundColor: historyLimitYear > rowId ? "#dddddd" : "",
                    backgroundColor: backgroundColor,
                    borderColor:
                      submitClicked &&
                      historicalClaimsErrorStore[absYear] &&
                      historicalClaimsErrorStore[absYear].carrier
                        ? "red"
                        : "",
                  }}
                  disabled={historyLimitYear > rowId}
                  onKeyPress={(e) => {
                    this.clearErr(e, historicalClaimsErrorStore, "carrier");
                  }}
                  value={
                    historicalClaims &&
                    historicalClaims[absYear] &&
                    historicalClaims[absYear]["carrier"]
                      ? historicalClaims[absYear]["carrier"]
                      : ""
                  }
                />
              </Form.Group>
            </td>
            <td className="per10">
              <Form.Group>
                <label for={`totRes-${absYear}`} title="Payroll Exposure">
                  Payroll Exposure
                </label>
                <NumberFormat
                  onFocus={(e) => {
                    this.clearErr(e, historicalClaimsErrorStore, "pyrlExp");
                  }}
                  id={`pyrlExp-${absYear}`}
                  autoComplete="off"
                  name="pyrlExp"
                  thousandSeparator={true}
                  onChange={(e) => this.handleChange(e, "pyrlExp")}
                  style={{
                    backgroundColor: historyLimitYear > rowId ? "#dddddd" : "",
                    backgroundColor: backgroundColor,
                    borderColor:
                      submitClicked &&
                      historicalClaimsErrorStore[absYear] &&
                      historicalClaimsErrorStore[absYear].pyrlExp
                        ? "red"
                        : "",
                  }}
                  disabled={historyLimitYear > rowId}
                  prefix={"$"}
                  className="text-center "
                  type="text"
                  // onBlur={zeroDefault}
                  value={
                    historicalClaims &&
                    historicalClaims[absYear] &&
                    historicalClaims[absYear]["pyrlExp"]
                      ? historicalClaims[absYear]["pyrlExp"]
                      : ""
                  }
                />
              </Form.Group>
            </td>
            <td className="per10">
              <Form.Group>
                <label for={`annPre-${absYear}`} title="Annual Premium">
                  Annual Premium
                </label>
                <NumberFormat
                  name="anualPremium"
                  id={`annPre-${absYear}`}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    this.clearErr(e, historicalClaimsErrorStore, "annPre");
                  }}
                  thousandSeparator={true}
                  onChange={(e) => this.handleChange(e, "annPre")}
                  style={{
                    backgroundColor: historyLimitYear > rowId ? "#dddddd" : "",
                    backgroundColor: backgroundColor,
                    borderColor:
                      submitClicked &&
                      historicalClaimsErrorStore[absYear] &&
                      historicalClaimsErrorStore[absYear].annPre
                        ? "red"
                        : "",
                  }}
                  disabled={historyLimitYear > rowId}
                  prefix={"$"}
                  className="text-center"
                  type="text"
                  value={
                    historicalClaims &&
                    historicalClaims[absYear] &&
                    historicalClaims[absYear]["annPre"]
                      ? historicalClaims[absYear]["annPre"]
                      : ""
                  }
                />
              </Form.Group>
            </td>
            <td className="per5">
              <Form.Group>
                <label
                  title="Number of claims"
                  className="mandatory"
                  for={`claims-${absYear}`}
                >
                  # of Claims
                </label>
                <NumberFormat
                  name="claims"
                  id={`claims-${absYear}`}
                  maxLength="2"
                  autoComplete="off"
                  onKeyPress={(e) => {
                    this.clearErr(e, historicalClaimsErrorStore, "claim");
                  }}
                  onChange={(e) => this.handleChange(e, "claim")}
                  style={{
                    backgroundColor: historyLimitYear > rowId ? "#dddddd" : "",
                    backgroundColor: backgroundColor,
                    borderColor:
                      submitClicked &&
                      historicalClaimsErrorStore[absYear] &&
                      historicalClaimsErrorStore[absYear].claim
                        ? "red"
                        : "",
                  }}
                  disabled={historyLimitYear > rowId}
                  className="text-center"
                  type="text"
                  value={
                    historicalClaims &&
                    historicalClaims[absYear] &&
                    historicalClaims[absYear]["claim"]
                      ? historicalClaims[absYear]["claim"]
                      : ""
                  }
                />
              </Form.Group>
            </td>
            <td className="per10">
              <Form.Group>
                <label
                  for={`totInc-${absYear}`}
                  className="mandatory"
                  title="Total Incurred"
                >
                  Total Incurred
                </label>
                <NumberFormat
                  onFocus={(e) => {
                    this.clearErr(e, historicalClaimsErrorStore, "totInc");
                  }}
                  id={`totInc-${absYear}`}
                  autoComplete="off"
                  name="totInc"
                  thousandSeparator={true}
                  onChange={(e) => this.handleChange(e, "totInc")}
                  style={{
                    backgroundColor: historyLimitYear > rowId ? "#dddddd" : "",
                    backgroundColor: backgroundColor,
                    borderColor:
                      submitClicked &&
                      historicalClaimsErrorStore[absYear] &&
                      historicalClaimsErrorStore[absYear].totInc
                        ? "red"
                        : "",
                  }}
                  disabled={historyLimitYear > rowId}
                  prefix={"$"}
                  className="text-center"
                  type="text"
                  // onBlur={(e) => this.zeroDefault(e, "totInc")}
                  value={
                    historicalClaims &&
                    historicalClaims[absYear] &&
                    historicalClaims[absYear]["totInc"]
                      ? historicalClaims[absYear]["totInc"]
                      : ""
                  }
                />
              </Form.Group>
            </td>
            <td className="per10">
              <Form.Group>
                <label
                  for={`totPaid-${absYear}`}
                  className="mandatory"
                  title="Total Paid"
                >
                  Total Paid
                </label>
                <NumberFormat
                  onFocus={(e) => {
                    this.clearErr(e, historicalClaimsErrorStore, "totPaid");
                  }}
                  onBlur={this.handleTotPaidBlur}
                  id={`totPaid-${absYear}`}
                  autoComplete="off"
                  name="totPaid"
                  thousandSeparator={true}
                  onChange={(e) => this.handleChange(e, "totPaid")}
                  style={{
                    backgroundColor: historyLimitYear > rowId ? "#dddddd" : "",
                    backgroundColor: backgroundColor,
                    borderColor:
                      submitClicked &&
                      historicalClaimsErrorStore[absYear] &&
                      historicalClaimsErrorStore[absYear].totPaid
                        ? "red"
                        : "",
                  }}
                  disabled={historyLimitYear > rowId}
                  prefix={"$"}
                  className="text-center"
                  type="text"
                  // onBlur={zeroDefault}
                  value={
                    historicalClaims &&
                    historicalClaims[absYear] &&
                    historicalClaims[absYear]["totPaid"]
                      ? historicalClaims[absYear]["totPaid"]
                      : ""
                  }
                />
              </Form.Group>
            </td>
            <td className="per10">
              <Form.Group>
                <label
                  for={`totRes-${absYear}`}
                  className="mandatory"
                  title="Total Reserves"
                >
                  Total Reserves
                </label>
                <NumberFormat
                  onFocus={(e) => {
                    this.clearErr(e, historicalClaimsErrorStore, "totRes");
                  }}
                  id={`totRes-${absYear}`}
                  autoComplete="off"
                  name="totRes"
                  thousandSeparator={true}
                  onChange={(e) => this.handleChange(e, "totRes")}
                  style={{
                    backgroundColor: historyLimitYear > rowId ? "#dddddd" : "",
                    backgroundColor: backgroundColor,
                    borderColor:
                      submitClicked &&
                      historicalClaimsErrorStore[absYear] &&
                      historicalClaimsErrorStore[absYear].totRes
                        ? "red"
                        : "",
                  }}
                  disabled={historyLimitYear > rowId}
                  prefix={"$"}
                  className="text-center "
                  type="text"
                  // onBlur={zeroDefault}
                  value={
                    historicalClaims &&
                    historicalClaims[absYear] &&
                    historicalClaims[absYear]["totRes"]
                      ? historicalClaims[absYear]["totRes"]
                      : ""
                  }
                />
              </Form.Group>
            </td>
          </tr>
        );
      } else {
        let yearId = Number(rowId) + 1;
        table.push(
          <tr id={rowIdDup} key={rowIdDup} className="row-no-coverage my-auto">
            <td className="no-coverage">
              <input
                type="checkbox"
                id={`check-${absYear}`}
                disabled={historyLimitYear >= yearId}
                checked={
                  historyLimitYear > yearId ||
                  (historicalClaimsCheck[absYear]
                    ? historicalClaimsCheck[absYear]
                    : false)
                }
                onChange={(e) => this.handleCheck(e)}
              />
              No coverage for the year {yearId}
            </td>
            {/* <td className="per5 text-left">coverage for the {yearId}</td> */}
          </tr>
        );
        absYear--;
      }
    }
    return table;
  };

  updateLocInfo = (stateKey, classCodeKey, dataKey, value) => {
    let { locInfo } = this.state;
    if (dataKey && (value === "" || value)) {
      locInfo[stateKey][classCodeKey][dataKey] = value.trim();
    } else if (!dataKey && !value) {
      if (!locInfo[stateKey]) {
        locInfo[stateKey] = {};
      }
      locInfo[stateKey][classCodeKey] = {};
    }
    this.setState({ locInfo });
  };

  removeLocInfo = (addressBlockNo, addressChildNo) => {
    let { locInfo } = this.state;
    delete locInfo[addressBlockNo][addressChildNo];
    this.setState({ locInfo });
    this.removeStateAggregateData(undefined, addressBlockNo, addressChildNo);
  };

  updateLocInfoErrorStore = (addressBlockNo, addressChildNo, key, state) => {
    let { locInfoErrorStore } = this.state;
    if (
      locInfoErrorStore &&
      locInfoErrorStore[addressBlockNo] &&
      locInfoErrorStore[addressBlockNo][addressChildNo]
    ) {
      locInfoErrorStore[addressBlockNo][addressChildNo][key] = state;
    }

    this.setState({ locInfoErrorStore });
  };

  handleMinMax = (addressBlockNo, addressChildNo) => {
    const { locInfo } = this.state;
    const keyList = ["ft", "pt", "payroll", "classCodeDescription"];
    if (locInfo && locInfo[addressBlockNo][addressChildNo]) {
      let allVals = keyList.every((e) => {
        return (
          e in locInfo[addressBlockNo][addressChildNo] &&
          locInfo[addressBlockNo][addressChildNo][e] !== ""
        );
      });
      if (allVals) {
        this.getMinMax(
          locInfo[addressBlockNo][addressChildNo],
          addressBlockNo,
          addressChildNo
        );
      }
    }
  };

  onDrop = (file) => {
    console.log("The drag and drop comp");
    console.log(file);
  };

  render() {
    const {
      addressCount,
      showUploadProgress,
      uploadError,
      historicalClaims,
      historicalClaimsCheck,
      historicalClaimsError,
      locInfoErrorStore,
      droppedFile,
      lossFileMissingError,
      locInfo,
      minMax,
      stateAggregate,
    } = this.state;
    const currProspect = sessionStorage.getItem("currProspect");
    const table = this.createTable();
    const currProspectJson = JSON.parse(currProspect);

    const childrenCode = [];
    for (let j = 0; j < addressCount; j++) {
      if (
        locInfo &&
        currProspectJson &&
        currProspectJson.prospectAddresses &&
        currProspectJson.prospectAddresses[j].addressId !== undefined
      ) {
        childrenCode.push(
          <FormCompLoc
            minMax={minMax}
            handleMinMax={this.handleMinMax}
            locInfoParent={locInfo}
            state={currProspectJson.prospectAddresses[
              j
            ].stateCode.toLowerCase()}
            key={j}
            addressBlockNo={j}
            updateLocInfo={this.updateLocInfo}
            removeLocInfo={this.removeLocInfo}
            locInfoErrorStoreParent={locInfoErrorStore}
            updateLocInfoErrorStore={this.updateLocInfoErrorStore}
          />
        );
      }
    }
    const childrenStateCode = [];
    if (currProspect && JSON.parse(currProspect).prospectAddresses) {
      const allAddress = JSON.parse(currProspect).prospectAddresses;
      let stateList = [];
      for (var j = 0; j < addressCount; j++) {
        let stateCode = Object.values(allAddress)[j].stateCode;
        if (!stateList.includes(stateCode)) {
          childrenStateCode.push(
            <StateAggr
              stateAggregateParent={stateAggregate}
              key={j}
              locations={allAddress}
              stateCode={stateCode}
            />
          );
          stateList.push(stateCode);
        }
      }
    }
    return (
      <div id="cmprRate" className="container-fluid per98">
        <NewProgress />
        <div id="compareRates">
          <form>
            <div className="row">
              <div className="formTitle title2 col-sm-8">
                Next, your projected payroll and historical claims details to
                get some quick comparisons
              </div>
              <div
                id="compNameRateComp"
                className="formTitle compName col-sm-4 d-flex justify-content-center"
              />
            </div>
            {/* <hr /> */}
            <div id="classCodeForm">{childrenCode}</div>
            <div className="bottomSpace">
              <div className="row">
                <div className="col-sm-8" />
                <div className="col-sm-4 d-flex justify-content-center">
                  <p className="aggregate-heading">
                    State Aggregate Manual Premium Range
                  </p>
                </div>
              </div>
              {childrenStateCode}
            </div>
            <div className="row clearfix" />
            <div className="row clearfix">
              <div className="col-sm-12">
                <div id="attachSec">
                  <span className="errMsg" />
                  <span className="no-lossrun-affidavit-heading">
                    Attach Loss Run/No Loss Affidavit *
                  </span>
                  <div className="row no-gutters row-dropzone">
                    <Dropzone
                      className="drop-main"
                      onDrop={this.dropReadFile}
                      accept="image/jpg, image/jpeg, image/png, application/pdf"
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} className="drop-zone">
                          <input className="drag-input" {...getInputProps()} />
                          <p>
                            Drag and drop or <span>browse</span> to choose files{" "}
                          </p>
                        </div>
                      )}
                    </Dropzone>
                    <div className="drop-info ml-2">
                      <span className="dropped-file">
                        {this.state.droppedFile}
                      </span>
                      {lossFileMissingError && (
                        <p id="upload-error" className="text-danger">
                          Loss form missing! Please upload a file.
                        </p>
                      )}
                      {showUploadProgress && (
                        <div className="dropped-file">Uploading...</div>
                      )}
                      {uploadError && (
                        <p id="upload-error" className="text-danger">
                          Error in uploading! Please try again
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <div id="docPreview">
                        <img alt="" />
                        {/* <p className="docUpldDisclaimer">
                          Note: While attaching you are confirming that the
                          forms are latest to best of your knowledge.
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p className="formTitle title3">
              Historical Claims (Previous Five Years)
            </p>
            {historicalClaimsError && (
              <p id="claims-error" className="text-danger">
                Claims submitted should be for consecutive years!
              </p>
            )}
            {/* <hr /> */}
            <div className="row grey-table-row">
              <table id="claimTable" className="grey-table">
                <thead>
                  <tr></tr>
                </thead>
                <tbody>{table}</tbody>
              </table>
            </div>
          </form>
          <div className="row">
            <div className="col-12 text-center">
              <button
                disabled={uploadError || showUploadProgress}
                type="button"
                onClick={() =>
                  this.submitComrRate(
                    locInfo,
                    droppedFile,
                    uploadError,
                    historicalClaims,
                    historicalClaimsCheck
                  )
                }
                className="btnGen btnSubmits"
              >
                CONTINUE
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FormCompareRate;
