import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import React, { useContext } from "react";
import logo from "../../images/insurecomp_mainlogo_var1.png";
import styles from "./Navbar.module.scss";
import { useState, useEffect } from "react";
import ContactPopup from "./ContactPopup";
import { ContactFormContext } from "./ContactFormContext";

function Header(props) {
  const [show, setShow] = useState(false);
  const { setShowForm } = useContext(ContactFormContext);

  const [scrollY, setScrollY] = useState(0);

  function logit() {
    setScrollY(window.pageYOffset);
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", logit);
    };
  });

  return (
    <Navbar
      variant="light"
      expand="lg"
      sticky="top"
      className={scrollY < 0 ? styles.navBar : styles.navBarScroll}
    >
      <Navbar.Brand href="/" className={"mr-auto " + styles.navbarBrand}>
        <img
          src={logo}
          width={300}
          height={85}
          layout="fixed"
          className={styles.brandlogo}
        />
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="main-navbar-nav" />
      <Navbar.Collapse
        id="main-navbar-nav"
        className={"mr-5 " + styles.navbarCollapse}
        style={{ width: "vh" }}
      >
        <Nav className={styles.nav}>
          <Nav.Link
            href="/schedule-demo"
            style={{ width: "150px", fontSize: "24px" }}
            className={
              props.active == 5
                ? "active " + styles.active + " " + styles.navLink
                : styles.navLink
            }
          >
            Demo
          </Nav.Link>
          <Nav.Link
            href="/#serve"
            style={{ width: "150px", fontSize: "24px" }}
            className={
              props.active == 1
                ? "active " + styles.active + " " + styles.navLink
                : styles.navLink
            }
          >
            Industries
          </Nav.Link>

          <Nav.Link
            href="https://blog.insurecomp.com/"
            style={{ width: "150px", fontSize: "24px" }}
            className={
              props.active == 2
                ? "active " + styles.active + " " + styles.navLink
                : styles.navLink
            }
          >
            Blogs
          </Nav.Link>

          <Nav.Link
            href="/#about"
            style={{ width: "150px", fontSize: "24px" }}
            className={
              props.active == 3
                ? "active " + styles.active + " " + styles.navLink
                : styles.navLink
            }
          >
            About us
          </Nav.Link>

          <Nav.Link
            style={{ width: "150px", fontSize: "24px" }}
            className={
              props.active == 4
                ? "active " + styles.active + " " + styles.navLink
                : styles.navLink
            }
            onClick={() => {
              setShowForm(true);
            }}
          >
            Contact us
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <ContactPopup />
    </Navbar>
  );
}

export default Header;
