import React, { Component } from 'react';
import axios from 'axios';
import { dashboardUrl } from "../../config";
import { getHeader } from "../../utils/common";
import ReactTable from "react-table-6";
import 'react-table-6/react-table.css'


class PoliciesDueExpiry extends Component {
  constructor(props) {
    super();
    this.state = {
      user: props.user,
      user_type: props.user_type,
      columns: [],
      rows: []
    }
  }
  async componentDidMount() {
    const { user } = this.state
    const header = await getHeader()
    await axios.get(dashboardUrl + '/api/getExpiry/' + user, header)
      .then(response => {
        this.setState({
          rows: response.data.exp_all['all'],
          data: response.data.exp_all,
          count: response.data.count
        })
        console.log(response.data.count)
      })
      .catch(err => {
        console.log(err)
      })
    let columns = []
    if (this.state.user_type.includes('Carrier')) {
      columns = [
        {
          Header: 'Insured  Name',
          accessor: 'business',
          // sort: 'asc',
          width: 130,
          headerClassName: 'table-header lt-align',
          Cell: row => <div><span title={row.value}>{row.value}</span></div>
        },
        {
          Header: 'St.',
          accessor: 'state',
          headerClassName: 'table-header',
          // sort: 'asc',
          width: 40,
          Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
        },
        {
          Header: 'Agent',
          accessor: 'agent',
          headerClassName: 'table-header',
          // sort: 'asc',
          width: 130,
          headerClassName: ' table-header lt-align',
          Cell: row => <div><span title={row.value}>{row.value}</span></div>
        },
        {
          Header: 'Eff. Dt.',
          accessor: 'effective',
          headerClassName: 'table-header',
          // sort: 'asc',
          width: 70,
          Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>

        },
        {
          Header: 'Exp. Dt.',
          accessor: 'expiry',
          headerClassName: 'table-header',
          sort: 'asc',
          width: 70,
          Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>

        },
        {
          Header: 'Premium',
          accessor: 'premium',
          headerClassName: ' table-header rt-align',
          // sort: 'asc',
          width: 70,
          Cell: row => <div style={{ textAlign: "right" }}>{row.value}</div>

        }]
    }
    else {
      columns = [
        {
          Header: 'Insured  Name',
          accessor: 'business',
          // sort: 'asc',
          width: 130,
          headerClassName: 'table-header lt-align',
          Cell: row => <div><span title={row.value}>{row.value}</span></div>
        },
        {
          Header: 'St.',
          accessor: 'state',
          headerClassName: 'table-header',
          // sort: 'asc',
          width: 40,
          Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
        },
        {
          Header: 'Issuing Co.',
          accessor: 'carrier',
          headerClassName: 'table-header',
          // sort: 'asc',
          width: 130,
          headerClassName: ' table-header lt-align',
          Cell: row => <div><span title={row.value}>{row.value}</span></div>
        },
        {
          Header: 'Eff. Dt.',
          accessor: 'effective',
          headerClassName: 'table-header',
          // sort: 'asc',
          width: 70,
          Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>

        },
        {
          Header: 'Exp. Dt.',
          accessor: 'expiry',
          headerClassName: 'table-header',
          sort: 'asc',
          width: 70,
          Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>

        },
        {
          Header: 'Premium',
          accessor: 'premium',
          headerClassName: ' table-header rt-align',
          // sort: 'asc',
          width: 70,
          Cell: row => <div style={{ textAlign: "right" }}>{row.value}</div>

        }]
    }
    this.setState({ columns })
  }
  handleChange = (e) => {
    const value = e.target.value;
    const { data } = this.state
    let rows;
    if (value == '30') {
      rows = data[30]
    }
    if (value == '60') {
      rows = data[60]
    }
    if (value == '90') {
      rows = data[90]
    }
    if (value == 'All') {
      rows = data['all']
    }
    this.setState({ rows })

  }

  render() {

    let { rows, count, columns } = this.state
    if (count < 10) { count = 10 }

    // console.log(data)
    return (
      <div><h4> Policies Expiring in next <select
        className="form drop-policy"
        onClick={this.handleChange}
      >
        <option value="All">All</option>
        <option value="90">90</option>
        <option value="60">60</option>
        <option value="30">30 </option>
      </select> days</h4>
        <ReactTable
          data={rows}
          columns={columns}
          pageSize={count}
          //  defaultPageSize={50}
          style={{ height: "293px", width: "535px" }}
          showPagination={false}
          resizable={false}
        />

      </div>

    )
  }
}

// const columns = ['Business','State','Carrier','Effective Date','Expiry Date','Premium']

// class PoliciesDueExpiry extends Component {
//     constructor(props){
//         super();
//         this.state ={
//             user : props.user,
//         }
//     }

//     generateHeader(){
//         let res=[]
//         for(var i=0;i<columns.length;i++){
//             res.push(<th>{columns[i]}</th>)
//         }
//         return res
//     }

//     generateTable(){
//         let res=[]
//         const {data} = this.state
//         if(data){
//             for(var i=0;i<data.length;i++){
//                 res.push(<tr>
//                     <td>{data[i][0]}</td>
//                     <td>{data[i][1]}</td>
//                     <td>{data[i][2]}</td>
//                     <td>{data[i][3]}</td>
//                     <td>{data[i][4]}</td>
//                     <td>{data[i][5]}</td>

//                 </tr>)
//             }
//         }
//         return res
//     }

//     async componentDidMount(){
//         const {user} = this.state
//         const header = await getHeader()
//         await axios.get(dashboardUrl+'/api/getExpiry/'+user,header)
//             .then(response=>{
//                 this.setState({data:response.data.list})
//                 console.log(this.state.data)
//             })
//             .catch(err=>{
//                 console.log(err)
//             })
//     }
//     render(){
//     return (
//         <div>
//                  {this.state.data && this.state.data.length>0 ?
//                     (<div style={{"overflow":"scroll","height":"280px","width":"560px","overflow":"auto"}}>
//                     <table border="1px" className="table table-striped">
//                      <thead>
//                      <tr>{this.generateHeader()}</tr>
//                      </thead>
//                      <tbody >
//                      {this.generateTable()}
//                      </tbody>
//                      </table>
//                      </div>) : 
//                      (
//                        <div style={{"height":"50px"}} className="text-center">No policies expiring soon </div> 
//                      )}
//          </div>
//     )}
// }
export default PoliciesDueExpiry;