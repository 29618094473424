import React, { Component } from "react";
import Grid from "./Grid";
import pageStyles from "../pages/NewHome.module.css";
export default function Serve() {
  return (
    <div className={"serve mt-5 " + pageStyles.sectionContainer} id="serve">
      <div className="row">
        <div className="col-md-12 mb-4">
          <h2>Who We Serve</h2>
        </div>
        {/*
        <div className="col-sm-3">
          <p className={styles.text}>
            I'm doing metal jumping jacks. I'm generally confused most of the
            time. I am not a killer. Dexter quote continues
          </p>
        </div>
        */}
      </div>
      <div className="servegrid">
        <div className="row">
          <Grid
            data={"Retail"}
            img={
              "https://res.cloudinary.com/mobiux-labs/image/upload/v1628753121/insurecomp/Group_276_g9ssso.png"
            }
          />
          <Grid
            data={"Restaurant"}
            img={
              "https://res.cloudinary.com/mobiux-labs/image/upload/v1628753122/insurecomp/Path_230_ien2qg.png"
            }
          />
          <Grid
            data={"Construction"}
            img={
              "https://res.cloudinary.com/mobiux-labs/image/upload/v1628753120/insurecomp/Group_268_dc2jiv.png"
            }
          />
          <Grid
            data={"Manufacturing"}
            img={
              "https://res.cloudinary.com/mobiux-labs/image/upload/v1628753120/insurecomp/Group_269_gwuorb.png"
            }
          />
          <Grid
            data={"Salons"}
            img={
              "https://res.cloudinary.com/mobiux-labs/image/upload/v1628753121/insurecomp/noun_Hair_Dryer_592546_brrmwq.png"
            }
          />
          <Grid
            data={"Professional services"}
            img={
              "https://res.cloudinary.com/mobiux-labs/image/upload/v1628753122/insurecomp/noun_User_49772_uix8xv.png"
            }
          />
          <Grid
            data={"Information Technology"}
            img={
              "https://res.cloudinary.com/mobiux-labs/image/upload/v1628753121/insurecomp/Group_274_qredma.png"
            }
          />
          <Grid
            data={"Trucking and transportation"}
            img={
              "https://res.cloudinary.com/mobiux-labs/image/upload/v1628753122/insurecomp/noun_Truck_2432634_mcpulk.png"
            }
          />
          <Grid
            data={"Warehouses and janitorial"}
            img={
              "https://res.cloudinary.com/mobiux-labs/image/upload/v1628753120/insurecomp/Group_272_oj3hg7.png"
            }
          />
          <Grid
            data={"Educational institutions"}
            img={
              "https://res.cloudinary.com/mobiux-labs/image/upload/v1628753120/insurecomp/Group_273_nhcvrs.png"
            }
          />
          <Grid
            data={"Financial services"}
            img={
              "https://res.cloudinary.com/mobiux-labs/image/upload/v1628753121/insurecomp/Group_285_m9rvow.png"
            }
          />
          <Grid
            data={"Healthcare"}
            img={
              "https://res.cloudinary.com/mobiux-labs/image/upload/v1628753121/insurecomp/Group_275_pv82jt.png"
            }
          />
          <Grid
            data={"Marketing"}
            img={
              "https://res.cloudinary.com/mobiux-labs/image/upload/v1628753122/insurecomp/noun_marketing_3500233_wynvuv.png"
            }
          />
          <Grid
            data={"Media"}
            img={
              "https://res.cloudinary.com/mobiux-labs/image/upload/v1628753120/insurecomp/Group_271_vrjitu.png"
            }
          />
          <Grid
            data={"Real Estate"}
            img={
              "https://res.cloudinary.com/mobiux-labs/image/upload/v1628753122/insurecomp/noun_Real_Estate_45113_i6wh4h.png"
            }
          />
          <Grid
            data={"Consumer Goods"}
            img={
              "https://res.cloudinary.com/mobiux-labs/image/upload/v1628753120/insurecomp/Group_270_a4fivx.png"
            }
          />
          <Grid
            data={"Business Services"}
            img={
              "https://res.cloudinary.com/mobiux-labs/image/upload/v1628753121/insurecomp/Group_277_v7cztz.png"
            }
          />
        </div>
      </div>
    </div>
  );
}
