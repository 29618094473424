import React from "react";

function Banner() {
  const screenWidth = window.innerWidth;
  let videoHeight = 450;
  if (screenWidth < 450) {
    videoHeight = 200;
  } else if (screenWidth < 550) {
    videoHeight = 250;
  } else if (screenWidth < 650) {
    videoHeight = 300;
  } else if (screenWidth < 800) {
    videoHeight = 350;
  } else if (screenWidth < 1000) {
    videoHeight = 400;
  }

  return (
    <div className="demo-banner d-flex flex-column align-items-center">
      <div className="banner-video w-100 d-flex justify-content-center">
        <iframe
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          height={videoHeight}
          width="85%"
          src="https://www.youtube.com/embed/QjcfacCaWoc?rel=0"
          frameBorder="0"
          title="YouTube video player"
          allowFullScreen
        ></iframe>
      </div>
      <div className="banner-text text-center">Welcome to InsureComp</div>
    </div>
  );
}

export default Banner;
