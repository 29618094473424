import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HowItWorksRates from "./component/pages/HowItWorksRates";
import HowItWorksQuotes from "./component/pages/HowItWorksQuotes";
import HowItWorksBind from "./component/pages/HowItWorksBind";
import HowItWorksBrochure from "./component/pages/HowItWorksBrochure";
import FAQPage from "./component/pages/FAQPage";
import Login from "./component/pages/Login";
import DataUpload from "./component/pages/DataUpload";
import Quote from "./component/pages/Quote";
import PanelUW from "./component/pages/PanelUW";
import PanelCompliance from "./component/pages/PanelCompliance";
import BinderDownload from "./component/common/BinderDownload";
import QuotesHistory from "./component/pages/QuotesHistory";
import UsMap from "./component/pages/Map";
import DashboardWithMap from "./component/pages/DashboardWithMap";
import DashboardLogin from "./component/pages/DashboardLogin";
import NewQuote from "./newcomponent/NewQuote";
import NewHome from "./component/pages/NewHome";
import BlogArchive from "./component/pages/BlogArchive";
import DemoTool from "./component/pages/DemoTool";
import DemoRouteHandler from "./component/pages/DemoRouteHandler";
import AssignmentDeveloper from "./component/pages/AssignmentDeveloper/AssignmentDeveloper";

class App extends Component {
  render() {
    var isLoggedIn = sessionStorage.getItem("isLoggedIn");

    return (
      <Router>
        <Switch>
          <Route path="/demo" component={DemoRouteHandler} />
          <Route exact path="/blog" component={BlogArchive} />
          <Route
            exact
            path="/assignments/developer"
            component={AssignmentDeveloper}
          />
          <Route exact path="/map" component={UsMap} />
          {/* <Route exact path='/newprofile' component={NewQuote} /> */}
          <Route exact path="/dashboard" component={DashboardWithMap} />
          <Route exact path="/dashboard-login" component={DashboardLogin} />
          <Route exact path="/how-it-works/rates" component={HowItWorksRates} />
          <Route
            exact
            path="/how-it-works/quotes"
            component={HowItWorksQuotes}
          />
          <Route exact path="/how-it-works/bind" component={HowItWorksBind} />
          <Route exact path="/faq" component={FAQPage} />
          <Route
            exact
            path="/how-it-works/brochure"
            component={HowItWorksBrochure}
          />
          {!isLoggedIn && <Route exact path="/Login" component={Login} />}
          {isLoggedIn && (
            <Route exact path="/DataUpload" component={DataUpload} />
          )}
          <Route exact path="/Quote" component={NewQuote} />
          {isLoggedIn && <Route exact path="/PanelUW" component={PanelUW} />}

          <Route exact path="/panelcompliance" component={PanelCompliance} />
          {isLoggedIn && (
            <Route exact path="/BinderDownload" component={BinderDownload} />
          )}
          {isLoggedIn && (
            <Route exact path="/QuotesHistory" component={QuotesHistory} />
          )}
          <Route exact path="/schedule-demo" component={DemoTool} />
          <Route exact path="/" component={NewHome} />
        </Switch>
      </Router>
    );
  }
}

export default App;
