import React, { Component } from "react";
import ReactDOM from "react-dom";
import { otherAddress, submitPayment } from "../../../utils/payment";
import {
  deleteAllCookies,
  showErrAlert,
  clearErr,
  blobPdfFromBase64String,
} from "../../../utils/common";
import { Link } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import Loader from "../../common/Loader";
import { awsUrl } from "../../../config";
import { Auth } from "aws-amplify";
import NewProgress from "../../../newcomponent/common/NewProgress";

const jQuery = require("jquery");
const CircularJSON = require("circular-json");
var atob = require("atob");
class FormPayment extends Component {
  constructor(props) {
    super();

    this.state = {
      flag: false,
      url: [],
      showUploadProgress: false,
      compName: "ABC",
      tAndC: true,
    };
    this.downloadPdf = this.downloadPdf.bind(this);
  }

  componentDidMount() {
    $("html, body").animate({ scrollTop: 0 }, 250);
  }

  componentWillMount() {
    this.downloadPdf();
  }

  unColor() {
    $("#mailAdd").css("border-color", "#ababab");
    $("#mailAdd").focus();
  }

  downloadPdf() {
    if (
      $("#mailAdd").val() === "Select One" ||
      $("#mailAdd").css("border-left-color") === "rgb(255, 0, 0)"
    ) {
      $("#mailAdd").css("border-color", "red");
      $("#mailAdd").siblings(".errMsg").html(" is required");
      $("#mailAdd").focus();
    } else {
      if (
        sessionStorage.getItem("require") !== undefined &&
        sessionStorage.getItem("require") !== null &&
        sessionStorage.getItem("currProspect") !== null &&
        sessionStorage.getItem("currProspect") !== undefined
      ) {
        let email = JSON.parse(sessionStorage.getItem("currProspect"))
          .emailAddress;
        let require = sessionStorage.getItem("require");
        let timestamp = require.split("@")[0];
        let carrierObj = {};
        let stateList = [];
        let etQuoteDetails = {};
        let mail_address = $("#mailAdd").val();

        require.split("!!").map((val1) => {
          let carrierName =
            "carrier_" + val1.split("+")[0].split("_").reverse()[0];
          let stateName = val1.split("_").reverse()[1];
          let address = val1.split("+").reverse()[0];
          if (!(carrierName in carrierObj)) {
            carrierObj[carrierName] = { state: [], address: [] };
          }
          // if(!())
          if (!carrierObj[carrierName].state.includes(stateName)) {
            carrierObj[carrierName].state.push(stateName);
          }
          if (!carrierObj[carrierName].address.includes(address)) {
            carrierObj[carrierName].address.push(address);
          }
        });

        window.scrollTo(0, 0);
        console.log("++data acord", etQuoteDetails);

        Auth.currentSession().then((res) => {
          const USER_TOKEN = res.idToken.jwtToken;
          const header = { headers: { Authorization: "Bearer " + USER_TOKEN } };
          for (let carrier in carrierObj) {
            this.setState({ showUploadProgress: true });
            etQuoteDetails[carrier] = {
              email: email,
              timestamp: timestamp,
              carrier: carrier,
              mail_address: mail_address,
              state: carrierObj[carrier].state,
              address: carrierObj[carrier].address,
            };

            axios
              .post(
                awsUrl + "/api/generateAccordForm/",
                etQuoteDetails[carrier],
                header
              )
              .then((res) => {
                this.setState({ showUploadProgress: false });
                const fileName = "Application.pdf";
                console.log("data: ", res.data);
                let pdf = res.data.data;
                let file = blobPdfFromBase64String(pdf);
                var fileURL = URL.createObjectURL(file);
                let url = this.state.url;
                url.push(fileURL);
                this.setState({ url });
              })
              .catch((err) => {
                this.setState({ showUploadProgress: false });
                console.log("Error:", err);
              });
          }
        });
      }
    }
  }

  render() {
    let compName = "";
    let { tAndC, submitPressed } = this.state;
    try {
      let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
      compName = currProspect.companyName;
    } catch (err) {
      console.log(err);
    }
    let userEnteredAddress = [];
    if (
      sessionStorage.getItem("require") !== undefined &&
      sessionStorage.getItem("require") !== null
    ) {
      let i = 0;
      let require = sessionStorage.getItem("require").split("!!");

      let address;
      for (let add of require) {
        let addArray = add.split("+")[1].split("_");
        let addArrayRefined = [];
        addArray.forEach((e) => {
          if (e !== "None") {
            addArrayRefined.push(e);
          }
        });
        address = addArrayRefined.join(", ");
        userEnteredAddress.push(
          <option key={i} value={add.split("+")[1].split("_").join(",  ")}>
            {address}
          </option>
        );
        i++;
      }
      userEnteredAddress.push(
        <option key={i} value="o">
          Other
        </option>
      );
    }
    return (
      <div id="payBind" className="container-fluid per98">
        <NewProgress />
        <div className="row no-gutters formTitle row-formTitle">
          <span className="formTitle">Review & Pay</span>
          <span className="formTitle compName">{compName}</span>
        </div>
        <div id="paymentQuote">
          <form>
            {this.state.url.length >= 1 ? (
              this.state.url.map((url) => (
                <div>
                  <div className="row row-acord-preview-heading">
                    <div className="col-sm-5 text-center col-acord-text">
                      <p className="" id="carierName"></p>
                    </div>
                    <div className="col-sm-5 text-center col-acord-download">
                      <p className="acord">
                        {"  "}
                        <a
                          id="accordLink"
                          className="linkLike"
                          href={url}
                          target="_blank"
                        >
                          {" "}
                          <img
                            src={require("../../../images/pdf2.png")}
                            alt=""
                          />
                          Download Acord
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="row no-gutters row-acord-preview">
                    <iframe src={url} frameborder="0"></iframe>
                  </div>
                </div>
              ))
            ) : (
              <div className="row no-gutters pdf-upload-placeholder">
                <h6>Please wait while loading the file</h6>
                <img
                  className="pdf-loader-gif"
                  src={require("../../../images/small_loader.gif")}
                  alt=""
                />
              </div>
            )}
            <nav aria-label="..."></nav>
            <div className="row">
              <div className="col-sm-6">
                <p className="payPageItems">
                  <span>
                    <input
                      id="authSign"
                      type="text"
                      placeholder="Enter Signatory Name"
                      onChange={clearErr}
                    />
                  </span>
                </p>
              </div>
              <div className="col-sm-6">
                <p className="payPageItems">
                  <span onClick={this.unColor}>
                    <select id="mailAdd" onChange={otherAddress}>
                      {userEnteredAddress}
                    </select>
                  </span>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div id="newAddress" className="row">
                  <div className="col-sm-12 payPageItems2 new">
                    Add New Address
                    <div className="row">
                      <div className="col-sm-3">
                        <input
                          type="text"
                          placeholder="Street Address One"
                          id="streetAddress1O"
                          autoComplete="off"
                          onChange={clearErr}
                        />
                      </div>
                      <div className="col-sm-3">
                        <input
                          type="text"
                          placeholder="Street Address Two"
                          id="streetAddress2O"
                          autoComplete="off"
                          onChange={clearErr}
                        />
                      </div>
                      <div className="col-sm-2">
                        <input
                          type="text"
                          placeholder="ZIP Code"
                          id="zipCodeO"
                          autoComplete="off"
                          onChange={clearErr}
                        />
                      </div>
                      <div className="col-sm-2">
                        <input
                          type="text"
                          placeholder="State Code"
                          id="stateCodeO"
                          autoComplete="off"
                          onChange={clearErr}
                        />
                      </div>
                      <div className="col-sm-2">
                        <input
                          type="text"
                          placeholder="City Name"
                          id="cityNameO"
                          autoComplete="off"
                          onChange={clearErr}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row row-tnc">
              <div className="payPageItems2 d-flex flex-column">
                <span className="d-flex align-items-center">
                  <input
                    id="tncCheck"
                    type="checkbox"
                    className="mr-2"
                    onClick={() =>
                      this.setState({ tAndC: !tAndC, submitPressed: false })
                    }
                    defaultChecked
                  />
                  <label for="tncCheck" className="m-0">
                    I hereby agree to the{" "}
                    <Link className="linkLike" to={"./Home"} target={"_blank"}>
                      Terms &amp; Conditions
                    </Link>
                  </label>
                </span>
                {submitPressed && !tAndC && (
                  <span className="errMsg">required</span>
                )}
              </div>
            </div>
          </form>
          <div className="row">
            <div className="col-sm-12 text-center">
              <button
                type="button"
                onClick={() => {
                  this.setState({ submitPressed: true });
                  submitPayment(tAndC);
                }}
                className="btnGen btnSubmits"
              >
                CONTINUE
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FormPayment;
