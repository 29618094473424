let mode;

if (process.env.REACT_APP_MODE) {
  mode = process.env.REACT_APP_MODE;
} else {
  mode = "dev";
}

console.log("env", process.env);

var localURL = "http://localhost:3000/dev";

// server_coreSetURL1;
export var awsUrl =
  "https://4z6zd38xpc.execute-api.us-east-1.amazonaws.com/dev";
var awsUrlProd = "https://ys4gqp9fg5.execute-api.us-east-1.amazonaws.com/prod";

// server_coreSetURL2;
export var awsUrl2 =
  "https://toyg4ps5yl.execute-api.us-east-1.amazonaws.com/dev";
var awsUrlProd2 = "https://nrqo6u875i.execute-api.us-east-1.amazonaws.com/prod";

export var demoAuthUrl = awsUrl2;

// dashboard URL
export var dashboardUrl =
  "https://jc0iytgvoa.execute-api.us-east-1.amazonaws.com/dev";
var dashboardUrlProd =
  "https://6hh82quapc.execute-api.us-east-1.amazonaws.com/prod";


  // mode = "local2"
if (mode === "local1") {
  awsUrl = localURL;
} else if (mode === "local2") {
  awsUrl2 = localURL;
} else if (mode === "locald") {
  dashboardUrl = localURL;
} else if (mode === "production") {
  awsUrl = awsUrlProd;
  awsUrl2 = awsUrlProd2;
  dashboardUrl = dashboardUrlProd;
}

export const versionNumber = "3.1.2";
