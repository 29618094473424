import React from "react";
import "./grid.css";
export default function Grid({ data, img }) {
  return (
    <div className="bullet col-md-3 col-sm-4 ">
      <div className="d-flex align-items-center mt-5 mb-2">
        <div
          className="d-flex align-items-center mr-4"
          style={{ height: "50px", width: "50px" }}
        >
          <img src={img}></img>
        </div>

        <span
          style={{
            display: "inline-block",
            color: "#2E2947",
            fontSize: "1.1rem",
          }}
        >
          {data}
        </span>
      </div>
    </div>
  );
}
