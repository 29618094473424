import React, { Component } from "react";
import { Link } from "react-router-dom";
import Dropworks from "./Dropworks";
import DropUserProfile from "./DropUserProfile";
import Dropresources from "./Dropresources";
import QuoteButtons from "./QuoteButtons";
import { openNav, closeNav } from "../../utils/common";
import { Auth } from "aws-amplify";
import UserIcon from "../../images/user-circle-regular.svg";
import { logout } from "../../utils/common";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: ["user"],
      userName: "",
      displayProfileMenu: false,
      displayQuotesMenu: false,
      displayQuoteButton: false,
      displayLoginButton: true,
      displayHowItWorks: false,
      displayResources: false,
      displayUnderWriterLink: false,
      displayComplianceLink: false,
      displayDashboard: false,
    };
  }

  componentDidMount() {
    let userRole = ["user"];

    var curre = Auth.currentSession()
      .then((res) => {
        console.log("Current Authenticated Response User: ", res);
        userRole = res.accessToken.payload["cognito:groups"];
        let userName = res.accessToken.payload.username.split("@")[0];
        if (userName === "rishabhladge") userName = "Demo";
        this.setState({
          userRole: userRole,
          userName: userName,
        });
        console.log("Role: ", this.state.userRole);
        if (userName !== "" && userRole && userRole.length > 0) {
          // This is a user who has logged in.
          this.setState({
            displayLoginButton: false,
            displayProfileMenu: true,
            displayQuoteButton: false,
          });
          sessionStorage.setItem("isLoggedIn", true);
          if (this.state.userRole.includes("User")) {
            this.setState({
              displayQuotesMenu: true,
              displayDashboard: true,
            });
          }
          if (this.state.userRole.includes("Carrier")) {
            this.setState({
              displayHowItWorks: false,
              displayResources: false,
              displayUnderWriterLink: true,
              displayDashboard: true,
            });
          } else if (
            this.state.userRole.includes("Compliance") ||
            this.state.userRole.includes("ComplianceAdmin")
          ) {
            this.setState({
              displayHowItWorks: false,
              displayResources: false,
              displayComplianceLink: true,
            });
          }
        } else {
          // This user could not log in.
          // No change in state required.
          console.log("User could not log in.");
          this.setState({
            displayHowItWorks: true,
            displayResources: true,
          });
        }
      })
      .catch((err) => {
        if (err === "No current user") {
          console.log("Current Authenticated User: ", err);
        }
        this.setState({
          displayHowItWorks: true,
          displayResources: true,
        });
      });
    console.log("Current Authenticated User: ", curre);
  }

  render() {
    const pathLogin = window.location.pathname === "/Login";
    const variant = this.props.variant;
    return (
      <nav id="navbarMain" className="new-navbar container-fluid">
        <div className="row h-100">
          <Link to={"/"} className="navbar-brand">
            <img
              className={
                variant === "large-logo" ? "new-logo boxed-logo" : "new-logo"
              }
              src={
                variant === "large-logo"
                  ? require("../../images/insurecomp_mainlogo_var1.png")
                  : require("../../images/insurecomp_mainlogo_var2.png")
              }
              alt="InsureComp Logo"
            />
          </Link>
          <div className="forBig">
            <ul className="text-right nav h-100 justify-content-end align-items-center">
              {this.state.displayHowItWorks && (
                <Link
                  id="HowItWorksRates"
                  to={"/how-it-works/brochure"}
                  className="d-flex align-items-center"
                >
                  <li className="nav-item">
                    <button className="btnInvisible">
                      <span className="nv-link">About Us</span>
                    </button>
                  </li>
                </Link>
              )}
              {this.state.displayResources && (
                <li id="Dropresources" className="nav-item d-flex">
                  <button className="btnInvisible">Resources</button>
                  <Dropresources />
                </li>
              )}
              {this.state.displayQuotesMenu && <QuoteButtons />}
              {!pathLogin && this.state.displayLoginButton && (
                <Link
                  id="loginButton"
                  to={"/Login"}
                  className="d-flex align-items-center"
                >
                  <li className="nav-item">
                    <button className="btnInvisible">
                      <span className="nv-link">Login</span>
                    </button>
                  </li>
                </Link>
              )}
              {pathLogin && (
                <a
                  id="loginButton"
                  data-toggle="tab"
                  href="#lgin"
                  className="d-flex align-items-center"
                >
                  <li>
                    <button className="btnInvisible">
                      <span className="nv-link">Login</span>
                    </button>
                  </li>
                </a>
              )}
              {this.state.displayQuoteButton && (
                <Link id="quoteLink" to={"/Quote"}>
                  <li className="nav-item d-flex">
                    {/* <button className="btn btn-warning">Get Quote</button> */}
                    <button className="btn btn-warning">
                      <span className="nv-link">Get Quote</span>
                    </button>
                  </li>
                </Link>
              )}
              {/* {this.state.displayDashboard && (
                <Link id="dashboard" to={"/dashboard"}>
                  <li className="nav-item d-flex">
                    <button className="btnInvisible">
                      <span className="nv-link">Dashboard</span>
                    </button>
                  </li>
                </Link>
              )} */}
              {this.state.displayUnderWriterLink && (
                <Link
                  id="panelUWLink"
                  to={"/PanelUW"}
                  className="d-flex align-items-center"
                >
                  <li className="nav-item">
                    {/* <button className="btnInvisible">Panel UW</button> */}
                    <button className="btnInvisible">
                      <span className="nv-link">Panel UW</span>
                    </button>
                  </li>
                </Link>
              )}
              {this.state.displayComplianceLink && (
                <Link
                  id="panelCompLink"
                  to={"/PanelCompliance"}
                  // className="d-flex align-items-center"
                >
                  <li className="d-flex h-100">
                    <button className="btnInvisible">
                      <span className="nv-link">Panel Compliance</span>
                    </button>
                  </li>
                </Link>
              )}
              {this.state.displayProfileMenu && (
                <li id="Dropprofile" className="d-flex1 align-items-center">
                  {/* <span className="nv-link">{this.state.userName}</span> */}
                  <span
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{ marginBottom: "-48px" }}
                  >
                    {this.state.userName}
                  </span>
                  <div className="dropdown-menu">
                    <a className="dropdown-item" onClick={logout}>
                      Logout
                    </a>
                  </div>
                  {/* <DropUserProfile username={this.state.userName} /> */}
                </li>
              )}
            </ul>
          </div>
        </div>
        <div id="mySidenav" className="sidenav forSmall">
          {/* <span className="linkLike" onClick={closeNav}>
            &times;
          </span> */}
          <ul className="text-right">
            {this.state.displayHowItWorks && (
              <li id="Dropworks">
                <button className="btnInvisible" type="button">
                  <span className="nv-link">How It Works</span>
                </button>
                <Dropworks />
              </li>
            )}
            {this.state.displayResources && (
              <li id="Dropresources">
                <button className="btnInvisible">Resources</button>
                <Dropresources />
              </li>
            )}
            {!pathLogin && this.state.displayLoginButton && (
              <Link to={"/Login"}>
                <li>
                  <button className="btnInvisible">
                    <span className="nv-link">Login</span>
                  </button>
                </li>
              </Link>
            )}
            {this.state.displayQuoteButton && (
              <Link id="quoteLink" to={"/Quote"}>
                <li>
                  {/* <button className="btnInvisible">Get Quote</button> */}
                  <button className="btnInvisible">
                    <span className="nv-link">Get Quote</span>
                  </button>
                </li>
              </Link>
            )}
            {this.state.displayDashboard && (
              <Link id="dashboard" to={"/dashboard"}>
                <li>
                  <button className="btnInvisible">
                    <span className="nv-link">Dashboard</span>
                  </button>
                </li>
              </Link>
            )}

            {this.state.displayComplianceLink && (
              <Link id="panelCompLink" to={"/PanelCompliance"}>
                <li>
                  <button className="btnInvisible">
                    <span className="nv-link">PanelCompliance</span>
                  </button>
                </li>
              </Link>
            )}
          </ul>
        </div>
      </nav>
    );
  }
}

export default Navbar;
