import React, { useState } from "react";

const ContactFormContext = React.createContext({});

const ContactFormProvider = (props) => {
  const [showForm, setShowForm] = useState(false);
  return (
    <ContactFormContext.Provider value={{ showForm, setShowForm }}>
      {props.children}
    </ContactFormContext.Provider>
  );
};

export { ContactFormContext, ContactFormProvider };
