import React, { useContext } from "react";
import image2 from "../../images/homepage//image2.png";
import styles from "./HomeHero.module.css";
import pageStyles from "../pages/NewHome.module.css";
import { ContactFormContext } from "./ContactFormContext";
import { Link } from "react-router-dom";

export default function Header() {
  const { setShowForm } = useContext(ContactFormContext);

  return (
    <div className={pageStyles.sectionContainer + " " + styles.heroRow}>
      <div className="row">
        <div
          className={
            "col-sm-6 col-md-6 col-lg-5 order-2 order-sm-1 d-flex align-items-center " +
            styles.textColumn
          }
        >
          <div className={styles.introtext}>
            <h1>Get Workers' Comp coverage instantly</h1>
            <p className="pt-3 font-weight-light">
              Cloud-based Modular Platform capable of estabilishing seamless
              integration with other payroll, policy administration and claims
              management system.
            </p>
            {process.env.REACT_APP_MODE === "production" ? (
              <button
                className={styles.getquote}
                onClick={() => {
                  setShowForm(true);
                }}
              >
                Get a quote
              </button>
            ) : (
              <Link to="/Quote">
                <button className={styles.getquote}>Get a quote</button>
              </Link>
            )}
          </div>
        </div>
        <div
          className={
            "col-sm-6 col-md-6 col-lg-7 order-1 order-sm-2 " +
            styles.imageColumn
          }
        >
          <div className={styles.background}>
            <img
              alt="Workers Compensation"
              src={image2}
              className={styles.bgImg + ""}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
